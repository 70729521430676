import React, { useCallback, useContext, useMemo, useRef } from "react";
import { Dayjs } from "dayjs";

import { useResizeObserver } from "@core/hooks";
import { storeContext } from "@core/store";
import { calculateElementOffset } from "@core/utils";
import { DatepickerRangeTypeKind } from "@core/types";

export function useDatepickerControls({
	ref,
	onSelect,
	zIndex = null,
	rangeType = "days",
	hasLockedRangeType = false,
	maxDate,
	minDate,
}: Options) {
	const store = useContext(storeContext);
	const spareRef = useRef<HTMLElement>(null);
	const targetRef = useMemo(() => ref || spareRef, [ref, spareRef]);
	const resizeObserver = useResizeObserver({
		ref: targetRef,
		rectDetectorType: "boundingClientRect",
	});

	const hide = useCallback(() => {
		store.datepicker.setIsVisible(false);
		store.datepicker.setHasLockedRangeType(false);
	}, [store]);

	const handleSelect = useCallback(
		(date: Dayjs) => {
			if (onSelect) {
				onSelect(date);
			}
		},
		[onSelect]
	);

	const show = useCallback(
		(
			options: {
				selectedDate?: Dayjs;
				rangeType?: DatepickerRangeTypeKind;
				onSelect?: SelectEventHandler;
				zIndex?: number;
				maxDate?: Dayjs;
				minDate?: Dayjs;
			} = {}
		) => {
			const targetSize = resizeObserver.getSize();
			const targetPosition = calculateElementOffset(targetRef.current);

			store.datepicker.setZIndex(options.zIndex || zIndex);
			store.datepicker.setHasLockedRangeType(hasLockedRangeType);
			store.datepicker.setOnSelect(options.onSelect || handleSelect);
			store.datepicker.setSelectedDate(options.selectedDate || null);
			store.datepicker.setRangeType(options.rangeType || rangeType);
			store.datepicker.setTargetPosition(targetPosition);
			store.datepicker.setTargetSize(targetSize);
			store.datepicker.setIsVisible(true);
			store.datepicker.setMaxDate(options.maxDate || maxDate || null);
			store.datepicker.setMinDate(options.minDate || minDate || null);
		},
		[
			resizeObserver,
			targetRef,
			store,
			zIndex,
			hasLockedRangeType,
			handleSelect,
			rangeType,
			maxDate,
			minDate,
		]
	);

	return { ref: targetRef, show, hide };
}

export interface Options {
	ref?: React.RefObject<HTMLElement>;
	rangeType?: DatepickerRangeTypeKind;
	hasLockedRangeType?: boolean;
	zIndex?: number | null;
	onSelect?: SelectEventHandler;
	maxDate?: Dayjs;
	minDate?: Dayjs;
}

type SelectEventHandler = (date: Dayjs) => void;
