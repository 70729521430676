import FastestValidator, {
	AsyncCheckFunction,
	MessagesType,
	SyncCheckFunction,
} from "fastest-validator";

function createErrorMessage(message: (params: { field: string; expected: string }) => string) {
	return message({ field: "{field}", expected: "{expected}" });
}

const messages: MessagesType = {
	string: createErrorMessage(() => `Значение поля должно быть строкой`),
	stringEmpty: createErrorMessage(() => `Поле не должно быть пустым`),
	stringLength: createErrorMessage(
		({ expected }) => `Значение поля должно быть длиной: ${expected} символов`
	),
	stringMax: createErrorMessage(({ expected }) => `Максимальная длина поля: ${expected} символов`),
	stringMin: createErrorMessage(({ expected }) => `Минимальная длина поля: ${expected} символов`),
	stringPattern: createErrorMessage(
		() => `Значение поля должно быть заполнено в необходимом формате`
	),
	email: createErrorMessage(() => `Значение поля должно содержать корректный email адрес`),
	emailEmpty: createErrorMessage(() => `Значение поля не должно быть пустым`),
	date: createErrorMessage(() => `Значение поля должно содержать корректную дату`),
	number: createErrorMessage(() => `Значение поля должно содержать число`),
	numberInteger: createErrorMessage(() => `Значение поля должно содержать целочисленное значение`),
	numberPositive: createErrorMessage(() => `Значение поля должно содержать положительное число`),
	stringAlpha: createErrorMessage(() => `Значение поля должно состоять из букв`),
	required: createErrorMessage(() => `Данное поле обязательно к заполнению`),
};

export const validator = new FastestValidator({ messages });

export function isValuesValid(
	check: SyncCheckFunction | AsyncCheckFunction,
	values: Record<string, string>
) {
	return check(values) === true;
}

export function getErrors(
	check: SyncCheckFunction | AsyncCheckFunction,
	values: Record<string, string>
) {
	const checkResult = check(values);

	if (checkResult === true) {
		return {};
	}

	return (checkResult as any[]).reduce(
		(acc, error) => ({
			...acc,
			[error.field]: error.message,
		}),
		{} as any
	);
}
