import React, { forwardRef, useMemo } from "react";
import { Tween as TweenImpl } from "react-gsap";

import { tweenConfig } from "@core/config";
import { tweenHelpers } from "@core/helpers";

export const Tween: React.FC<Props> = forwardRef<any, Props>(
	({ children, force3D, isHighPerfomanceMode = true, ...rest }, ref) => {
		const cssProps = useMemo(() => tweenHelpers.transformTweenPropsToCssProps(rest), [rest]);

		return (
			<TweenImpl {...rest} ref={ref} force3D={true}>
				{React.Children.map(children, (element) => {
					if (!React.isValidElement(element)) {
						return null;
					}

					return React.cloneElement(element, {
						[tweenConfig.tweenDataAttributeName]: true,
						style: {
							willChange: cssProps.join(","),
						},
					});
				})}
			</TweenImpl>
		);
	}
);

export type Props = TweenImpl["props"] & { isHighPerfomanceMode?: boolean };
