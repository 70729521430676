import React, { useCallback, useEffect, useMemo, useRef } from "react";
import ResizeObserver from "resize-observer-polyfill";

import { useLocalStoreFlat } from "@core/hooks";

export function useResizeObserver<T extends HTMLElement>({
	ref,
	onResize,
	onPositionChange,
	safelyMode = true,
	rectDetectorType = "resizeObserverEntry",
}: Options<T> = {}) {
	const spareRef = useRef<T>(null);
	const targetRef = useMemo(() => ref || spareRef, [ref, spareRef]);
	const localStore = useLocalStoreFlat({
		DOMRect: { width: 0, height: 0, top: 0, left: 0, right: 0, bottom: 0 },
	} as { DOMRect: Size & Position });

	const resizeObserver = useMemo(
		() =>
			new ResizeObserver((entries) => {
				const entry = entries[0];

				if (entry) {
					const DOMRect =
						rectDetectorType === "resizeObserverEntry"
							? entry.contentRect
							: entry.target.getBoundingClientRect();

					const position: Position = {
						top: DOMRect.top || 0,
						left: DOMRect.left || 0,
						bottom: DOMRect.bottom || 0,
						right: DOMRect.right || 0,
					};
					const size: Size = {
						width: DOMRect.width || 0,
						height: DOMRect.height || 0,
					};

					if (safelyMode && size.width === 0 && size.height === 0) {
						return;
					}

					if (
						onResize &&
						(localStore.DOMRect.width !== size.width || localStore.DOMRect.height !== size.height)
					) {
						onResize(size);
					}

					if (
						onPositionChange &&
						(localStore.DOMRect.top !== position.top ||
							localStore.DOMRect.bottom !== position.bottom ||
							localStore.DOMRect.left !== position.left ||
							localStore.DOMRect.right !== position.right)
					) {
						onPositionChange(position);
					}

					localStore.setDOMRect({ ...size, ...position });
				}
			}),
		[localStore, onPositionChange, onResize, rectDetectorType, safelyMode]
	);

	const getSize = useCallback((): Size => {
		return {
			width: localStore.DOMRect.width,
			height: localStore.DOMRect.height,
		};
	}, [localStore]);

	const getPosition = useCallback((): Position => {
		return {
			top: localStore.DOMRect.top,
			bottom: localStore.DOMRect.bottom,
			left: localStore.DOMRect.left,
			right: localStore.DOMRect.right,
		};
	}, [localStore]);

	useEffect(() => {
		const targetElement = targetRef.current;

		if (targetElement) {
			resizeObserver.observe(targetElement);
		}

		return () => {
			if (targetElement) {
				resizeObserver.unobserve(targetElement);
			}
		};
	}, [resizeObserver, targetRef]);

	return { ref: targetRef, getSize, getPosition };
}

export interface Options<T extends HTMLElement> {
	ref?: React.RefObject<T>;
	safelyMode?: boolean;
	rectDetectorType?: "resizeObserverEntry" | "boundingClientRect";
	onResize?: (size: Size) => void;
	onPositionChange?: (position: Position) => void;
}

export type Position = { top: number; left: number; right: number; bottom: number };
export type Size = { width: number; height: number };
