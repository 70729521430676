import React, { memo, useCallback, useEffect, useRef } from "react";
import classNames from "classnames";

import { Prompt } from "@components/simple/Prompt";

import styles from "./styles.module.scss";

export const PromptWrapper: React.FC<Props> = memo(
	({ children, className, type, text, onClick, ...rest }) => {
		const promptGroupRef = useRef<HTMLDivElement>(null);
		const textGroupRef = useRef<HTMLDivElement>(null);

		const handleDocumentClick = useCallback((event: Event) => {
			const targetElement = event.target as HTMLElement;
			const promptGroupElement = promptGroupRef.current;

			if (promptGroupElement && !promptGroupElement.contains(targetElement)) {
				promptGroupElement.classList.remove(styles.visible);
			}
		}, []);

		const handlePromptGroupClick = useCallback(() => {
			const promptGroupElement = promptGroupRef.current;
			const textGroupElement = textGroupRef.current;

			if (promptGroupElement && textGroupElement) {
				const textGroupClientRect = textGroupElement.getBoundingClientRect();
				const windowWidth = window.innerWidth;

				if (textGroupClientRect.width + textGroupClientRect.left > windowWidth) {
					textGroupElement.style.setProperty("left", "initial");
					textGroupElement.style.setProperty("right", "0");
				} else if (textGroupClientRect.right < 0) {
					textGroupElement.style.setProperty("left", "1rem");
					textGroupElement.style.setProperty("right", "initial");
				}

				promptGroupElement.classList.toggle(styles.visible);
			}
		}, []);

		useEffect(() => {
			document.addEventListener("click", handleDocumentClick);

			return () => {
				document.removeEventListener("click", handleDocumentClick);
			};
		}, [handleDocumentClick]);

		return (
			<div className={classNames(className, styles.promptWrapper, styles[`type-${type}`])} {...rest}>
				{children}
				<div ref={promptGroupRef} className={styles.promptGroup} onClick={handlePromptGroupClick}>
					<Prompt className={styles.prompt} />
					<div ref={textGroupRef} className={styles.textGroup}>
						{text}
					</div>
				</div>
			</div>
		);
	}
);

export interface Props extends React.ComponentProps<"div"> {
	type: "checkbox" | "input";
	text: string;
}
