import { Dayjs } from "dayjs";

import { schemaHelpers } from "@core/helpers";
import { DatepickerRangeTypeKind } from "@core/types";

export const datepicker = schemaHelpers.createStoreSchemaFlat({
	targetSize: {
		width: 0,
		height: 0,
	},
	targetPosition: {
		top: 0,
		left: 0,
	},
	zIndex: null as number | null,
	isVisible: false as boolean,
	selectedDate: null as Dayjs | null,
	element: null as HTMLDivElement | null,
	hasLockedRangeType: false as boolean,
	rangeType: null as DatepickerRangeTypeKind | null,
	onSelect: null as ((date: Dayjs) => void) | null,
	maxDate: null as Dayjs | null,
	minDate: null as Dayjs | null,
});
