import React from "react";
import classNames from "classnames";

import { Reasons } from "@components/ordinary/Reasons";

import { Section } from "@components/simple/Section";

import { Container } from "@components/ui/Container";
import { ContainerWrapper } from "@components/ui/ContainerWrapper";
import { CardContent } from "@components/ui/CardContent";
import { Title } from "@components/ui/Title";

import { getIconImage } from "@assets";

import { steps, reasons } from "./data";
import styles from "./styles.module.scss";

export const StepsSection: React.FC = () => {
	return (
		<>
			<div>
				<Container>
					<Reasons className={styles.reasons} reasons={reasons} />
				</Container>
			</div>
			<Section className={styles.section}>
				<ContainerWrapper className={classNames(styles.wrapper, "card")}>
					<Container>
						<CardContent className={styles.content}>
							<Title className={styles.title}>
								Оформите полис в <span>4 шага</span>
							</Title>
							<div className={styles.steps}>
								{steps.map((step, index) => (
									<div key={`step-${index}`} className={styles.step}>
										<div className={styles.icon}>{getIconImage(step.iconName)}</div>
										<p className={styles.description}>{step.description}</p>
									</div>
								))}
							</div>
						</CardContent>
					</Container>
				</ContainerWrapper>
			</Section>
		</>
	);
};
