import React, { useContext, useCallback } from "react";
import { Observer } from "mobx-react-lite";

import { OrderBeingProcessed } from "@modals/OrderBeingProcessed";

import { Datepicker } from "@components/smart/Datepicker";

import { storeContext } from "@core/store";

export const Layout: React.FC<Props> = ({ className, children, ...rest }) => {
	const store = useContext(storeContext);

	const handleDatepickerRef = useCallback(
		(element: HTMLDivElement | null) => {
			store.datepicker.setElement(element);
		},
		[store]
	);

	return (
		<main className={className} {...rest}>
			{children}
			<div>
				<Observer>
					{() => (
						<Datepicker
							ref={handleDatepickerRef}
							isVisible={store.datepicker.isVisible}
							targetSize={store.datepicker.targetSize}
							targetPosition={store.datepicker.targetPosition}
							zIndex={store.datepicker.zIndex || undefined}
							onSelect={store.datepicker.onSelect || undefined}
							rangeType={store.datepicker.rangeType || undefined}
							selectedDate={store.datepicker.selectedDate || undefined}
							hasLockedRangeType={store.datepicker.hasLockedRangeType}
							maxDate={store.datepicker.maxDate || undefined}
							minDate={store.datepicker.minDate || undefined}
						/>
					)}
				</Observer>
			</div>
			<div>
				<OrderBeingProcessed />
			</div>
		</main>
	);
};

export interface Props extends React.ComponentProps<"div"> {}
