import React, { memo } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const Reasons: React.FC<Props> = memo(({ children, className, reasons, ...rest }) => {
	return (
		<div className={classNames(className, styles.reasons)} {...rest}>
			{reasons.map((reason, index) => (
				<div key={`reason-${index}`} className={styles.reason}>
					<p className={styles.title}>{reason.title}</p>
					<p className={styles.description}>{reason.description}</p>
				</div>
			))}
		</div>
	);
});

export interface Props extends React.ComponentProps<"div"> {
	reasons: { title: React.ReactNode; description: React.ReactNode }[];
}
