import React, { useCallback, forwardRef } from "react";
import { Observer } from "mobx-react-lite";
import { Dayjs } from "dayjs";
import classNames from "classnames";

import { useDatepicker } from "@core/hooks";
import { dateHelpers } from "@core/helpers";
import { mergeRefs } from "@core/utils";
import { DatepickerRangeTypeKind } from "@core/types";

import styles from "./styles.module.scss";

export const Datepicker = forwardRef<HTMLDivElement, Props>(
	(
		{
			zIndex,
			onSelect,
			rangeType,
			selectedDate,
			maxDate,
			minDate,
			isVisible = false,
			hasLockedRangeType = false,
			targetSize = { width: 0, height: 0 },
			targetPosition = { left: 0, top: 0 },
		},
		ref
	) => {
		const datepicker = useDatepicker({ date: selectedDate, rangeType: rangeType, minDate, maxDate });

		const getColumnsCountRelativeRangeType = useCallback(() => {
			switch (datepicker.getRangeType()) {
				case "days":
					return 7;
				case "months":
					return 3;
				case "years":
					return 4;
			}
		}, [datepicker]);

		const createDateClickHandler = useCallback(
			(date: Dayjs) => (event: React.MouseEvent) => {
				event.preventDefault();
				event.stopPropagation();

				if (datepicker.getRangeType() !== "days" && !hasLockedRangeType) {
					datepicker.setViewedDate(date);
					datepicker.switchRangeTypeStepByStep("down");
				} else {
					datepicker.setSelectedDate(date);

					if (onSelect) {
						onSelect(date);
					}
				}
			},
			[datepicker, hasLockedRangeType, onSelect]
		);

		const handleSwitchScopeTypeOnTop = useCallback(() => {
			if (!hasLockedRangeType) {
				datepicker.switchRangeTypeStepByStep("top");
			}
		}, [datepicker, hasLockedRangeType]);

		const handleSlideNext = useCallback(() => {
			datepicker.slide("next");
		}, [datepicker]);

		const handleSlidePrev = useCallback(() => {
			datepicker.slide("prev");
		}, [datepicker]);

		return (
			<Observer>
				{() => (
					<div
						ref={mergeRefs(ref, datepicker.ref)}
						className={classNames(
							styles.datepicker,
							isVisible && styles.visible,
							styles[datepicker.calculatePositionDirection(targetPosition)]
						)}
						style={{
							...datepicker.calculatePosition(targetSize, targetPosition),
							...(zIndex ? { zIndex } : {}),
						}}>
						<div className={classNames(styles.content, "card")}>
							<div className={styles.head}>
								<div className={styles.controls}>
									<button type='button' className={styles.item} onClick={handleSlidePrev}>
										«
									</button>
									<button
										type='button'
										className={classNames(styles.item, styles.title)}
										onClick={handleSwitchScopeTypeOnTop}>
										<Observer>
											{() => (
												<>
													{datepicker.getRangeType() === "years"
														? datepicker.getViewedDateLabelsBounds().join("-")
														: `${datepicker.getViewedMonthName()} ${datepicker.getViewedYearNumber()}`}
												</>
											)}
										</Observer>
									</button>
									<button type='button' className={styles.item} onClick={handleSlideNext}>
										»
									</button>
								</div>
								<Observer>
									{() =>
										datepicker.getRangeType() === "days" ? (
											<div className={styles.row}>
												{dateHelpers.weekdayNameList.map((weekdayName, index) => (
													<p key={`weekday-${index}`} className={styles.item}>
														{weekdayName}
													</p>
												))}
											</div>
										) : null
									}
								</Observer>
							</div>
							<Observer>
								{() => (
									<div
										className={classNames(styles.row, styles.dates)}
										style={{ ["--columns-count" as any]: getColumnsCountRelativeRangeType() }}>
										{datepicker
											.getRangeWithMetadata()
											.map(({ date, isCurrent, insideInRange, isSelected, label, isAccepted }, index) => (
												<button
													key={`date-${index}`}
													type='button'
													className={classNames(
														styles.item,
														datepicker.getRangeType() === "months" && styles.month,
														datepicker.getRangeType() === "years" && styles.year,
														isCurrent && styles.current,
														!insideInRange && styles.outside,
														isSelected && styles.selected
													)}
													onClick={createDateClickHandler(date)}
													disabled={!isAccepted}>
													{label}
												</button>
											))}
									</div>
								)}
							</Observer>
						</div>
					</div>
				)}
			</Observer>
		);
	}
);

export interface Props {
	rangeType?: DatepickerRangeTypeKind;
	selectedDate?: Dayjs;
	isVisible?: boolean;
	targetPosition?: { left: number; top: number };
	targetSize?: { width: number; height: number };
	hasLockedRangeType?: boolean;
	zIndex?: number;
	onSelect?: (date: Dayjs) => void;
	maxDate?: Dayjs;
	minDate?: Dayjs;
}
