import { schemaHelpers, dateHelpers } from "@core/helpers";
import { fieldConfig } from "@core/config";

export const formSchema = schemaHelpers.asFormSchema({
	total: schemaHelpers.inputMask(
		{
			label: "Сумма кредита",
			maskOptions: fieldConfig.maskOptions.groupedNumber,
		},
		"number|convert|min:1|integer|positive"
	),
	firstname: schemaHelpers.input(
		{
			label: "Имя",
		},
		`string|singleLine|trim`
	),
	phone: schemaHelpers.inputMask(
		{
			label: "Телефон",
			maskOptions: {
				regex: fieldConfig.patterns.phoneInput,
				autoUnmask: false,
			},
		},
		`string|pattern:${fieldConfig.patterns.phone}`
	),
	birthDate: schemaHelpers.inputDatepicker(
		{
			label: "Дата рождения",
			autoComplete: "off",
			...fieldConfig.datepickerOptions.date({ zIndex: 9999, maxDate: dateHelpers.getCurrentDate() }),
		},
		`string|pattern:${fieldConfig.patterns.date}`
	),
	isRealty: schemaHelpers.checkbox({ label: "Недвижимость", value: "true" }),
	isLifeAndHealth: schemaHelpers.checkbox({ label: "Жизнь и здоровье", value: "true" }),
	gender: schemaHelpers.select(
		{
			label: "Пол",
			options: [
				{ label: "Мужской", value: "1" },
				{ label: "Женский", value: "2" },
			],
			defaultOption: {
				label: "Не выбран",
				value: "",
			},
		},
		{
			type: "enum",
			values: ["1", "2"],
			messages: {
				enumValue: "Вы должны выбрать мужской или женский пол",
				// string: "Please check your firstname",
				// stringMin: "Your firstname is too short",
			},
		}
	),
});
