import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { Dayjs } from "dayjs";

import { useDatepickerControls } from "@core/hooks";
import { storeContext } from "@core/store";
import { dateHelpers } from "@core/helpers";
import { triggerEvent, mergeCallbacks } from "@core/utils";
import { DatepickerRangeTypeKind } from "@core/types";

export function useInputDatepicker({
	ref,
	format,
	zIndex = null,
	rangeType = "days",
	hasLockedRangeType = false,
	showWhenReadOnly = false,
	maxDate,
	minDate,
}: Options) {
	const store = useContext(storeContext);
	const spareRef = useRef<HTMLInputElement>(null);
	const targetRef = useMemo(() => {
		return ref || spareRef;
	}, [ref, spareRef]);

	const getParsedInputValue = useCallback(() => {
		const parsedValue = dateHelpers.parse(targetRef.current?.value || "", format);

		if (parsedValue.isValid()) {
			return parsedValue;
		}

		return null;
	}, [format, targetRef]);

	const handleChange = useCallback(() => {
		if (targetRef.current) {
			const parsedValue = getParsedInputValue();

			if (parsedValue) {
				store.datepicker.setSelectedDate(parsedValue);
			}
		}
	}, [targetRef, getParsedInputValue, store]);

	const datepickerControls = useDatepickerControls({
		ref: targetRef,
		zIndex,
		rangeType,
		hasLockedRangeType,
		maxDate,
		minDate,
	});

	const handleSelect = useCallback(
		(date: Dayjs) => {
			const targetElement = targetRef.current;

			if (targetElement) {
				const value = date.format(format);

				targetElement.value = value;
				triggerEvent(targetElement, "input", {
					asValueSetter: true,
					value: value,
				});

				datepickerControls.hide();
			}
		},
		[format, targetRef, datepickerControls]
	);

	const handleFocus = useCallback(() => {
		const targetElement = targetRef.current;

		if (targetElement && (!targetElement?.readOnly || showWhenReadOnly)) {
			const parsedValue = getParsedInputValue();

			datepickerControls.show({
				selectedDate: parsedValue || undefined,
				onSelect: handleSelect,
			});
		}
	}, [datepickerControls, getParsedInputValue, handleSelect, targetRef, showWhenReadOnly]);

	const isSomeOfTargetsFocusedRelativeEventTarget = useCallback(
		(element: any) => {
			const datepickerElement = store.datepicker.element;
			const targetNode = targetRef.current;

			return (
				(datepickerElement && datepickerElement.contains(element)) ||
				(targetNode && targetNode.contains(element))
			);
		},
		[store, targetRef]
	);

	const handleAnyEvent = useCallback(
		(event: Event) => {
			if (!isSomeOfTargetsFocusedRelativeEventTarget(event.target)) {
				datepickerControls.hide();
			}
		},
		[datepickerControls, isSomeOfTargetsFocusedRelativeEventTarget]
	);

	const handleDocumentKeyDown = useCallback(
		(event: Event) => {
			if (
				(event as any).key === "Escape" &&
				!isSomeOfTargetsFocusedRelativeEventTarget(event.target)
			) {
				datepickerControls.hide();
			}
		},
		[datepickerControls, isSomeOfTargetsFocusedRelativeEventTarget]
	);

	const bind = useCallback(
		(
			options: {
				onChange?: React.ChangeEventHandler<HTMLInputElement>;
				onFocus?: React.FocusEventHandler<HTMLInputElement>;
			} = {}
		) => {
			return {
				ref: targetRef,
				onChange: mergeCallbacks(options.onChange, handleChange),
				onFocus: mergeCallbacks(options.onFocus, handleFocus),
			};
		},
		[handleChange, handleFocus, targetRef]
	);

	useEffect(() => {
		document.addEventListener("focus", handleAnyEvent, true);
		document.addEventListener("keydown", handleDocumentKeyDown);
		document.addEventListener("mousedown", handleAnyEvent);

		return () => {
			document.removeEventListener("focus", handleAnyEvent, true);
			document.removeEventListener("keydown", handleDocumentKeyDown);
			document.removeEventListener("mousedown", handleAnyEvent);
		};
	}, [handleAnyEvent, handleDocumentKeyDown]);

	return {
		ref: targetRef,
		bind,
		handleFocus,
		handleChange,
	};
}

export interface Options {
	ref?: React.RefObject<HTMLInputElement>;
	format: string;
	rangeType?: DatepickerRangeTypeKind;
	zIndex?: number | null;
	hasLockedRangeType?: boolean;
	showWhenReadOnly?: boolean;
	maxDate?: Dayjs;
	minDate?: Dayjs;
}
