import { schemaHelpers } from "@core/helpers";
import { fieldConfig } from "@core/config";

export const formSchema = schemaHelpers.asFormSchema({
	firstname: schemaHelpers.input(
		{
			label: "Имя",
		},
		`string|empty:false|singleLine|trim`
	),
	phone: schemaHelpers.inputMask(
		{
			label: "Телефон",
			maskOptions: {
				regex: fieldConfig.patterns.phoneInput,
				autoUnmask: false,
			},
		},
		`string|pattern:${fieldConfig.patterns.phone}`
	),
});
