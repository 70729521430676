import { useCallback } from "react";

import { useLocalStoreFlat } from "@core/hooks";

export function useSlider(slidesCount: number) {
	const localStore = useLocalStoreFlat({
		target: 0,
	});

	const next = useCallback(() => {
		if (localStore.target === slidesCount - 1) {
			localStore.setTarget(0);
		} else {
			localStore.setTarget(localStore.target + 1);
		}
	}, [localStore, slidesCount]);

	const prev = useCallback(() => {
		if (localStore.target === 0) {
			localStore.setTarget(slidesCount - 1);
		} else {
			localStore.setTarget(localStore.target - 1);
		}
	}, [localStore, slidesCount]);

	const getCurrentIndex = useCallback(() => {
		return localStore.target;
	}, [localStore]);

	const setCurrentIndex = useCallback(
		(index: number) => {
			localStore.setTarget(index);
		},
		[localStore]
	);

	return {
		next,
		prev,
		setCurrentIndex,
		getCurrentIndex,
	};
}
