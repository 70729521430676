import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { StoreProvider } from "@core/store";
import { App } from "./app";

import reportWebVitals from "./reportWebVitals";
import "@styles/main.scss";

const appElement = document.getElementById("app");

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<StoreProvider>
				<App />
			</StoreProvider>
		</BrowserRouter>
	</React.StrictMode>,
	appElement
);

reportWebVitals();
