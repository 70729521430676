import React, { useCallback } from "react";

import { Modal } from "@components/smart/Modal";

import { Button } from "@components/ui/Button";

import { useModal } from "@core/hooks";
import { ModalType } from "@core/types";

import styles from "./styles.module.scss";

export const OrderBeingProcessed: React.FC = () => {
	const modal = useModal();

	const handleButtonClick = useCallback(() => {
		modal.hide();
	}, [modal]);

	return (
		<Modal type={ModalType.OrderBeingProcessed} className={styles.modal}>
			<div className={styles.titleGroup}>
				<p className={styles.title}>Заявка принята!</p>
				<p className={styles.subtitle}>
					Совсем скоро с Вами свяжется
					<br />
					наш менеджер!
				</p>
			</div>
			<div>
				<Button theme='primary-red' onClick={handleButtonClick}>
					Понятно
				</Button>
			</div>
		</Modal>
	);
};
