import React, { forwardRef, useMemo, useRef } from "react";

import { useInputDatepicker, UseInputDatepickerOptions } from "@core/hooks";
import { mergeRefs } from "@core/utils";

export const FieldDatepicker = forwardRef<HTMLInputElement, Props>(({ children, ...rest }, ref) => {
	const childrenRef = useRef<HTMLInputElement>(null);
	const mergedRefs = useMemo(() => mergeRefs<HTMLInputElement>(childrenRef, ref), [ref]);
	const datepickerInput = useInputDatepicker({
		ref: childrenRef,
		...rest,
	});

	return (
		<>
			{React.Children.map(children, (element) => {
				if (!React.isValidElement(element)) {
					return null;
				}

				const { onChange, onFocus } = element.props;

				return React.cloneElement(element, {
					...datepickerInput.bind({ onChange: onChange, onFocus: onFocus }),
					ref: mergedRefs,
				});
			})}
		</>
	);
});

export interface Props extends Omit<UseInputDatepickerOptions, "ref"> {
	children?: React.ReactNode;
}
