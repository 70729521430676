import React, { forwardRef } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const Section = forwardRef<HTMLDivElement, Props>(
	({ children, className, ...rest }, ref) => {
		return (
			<section ref={ref} className={classNames(className, styles.section)} {...rest}>
				{children}
			</section>
		);
	}
);

export interface Props extends React.ComponentProps<"div"> {}
