import React, { memo } from "react";
import classNames from "classnames";

import { BannerLayout } from "@components/ordinary/BannerLayout";

import { Image } from "@components/ui/Image";

import BackgroundImage from "@assets/images/banner-2.png";
import BackgroundImageMobile from "@assets/images/banner-2-mobile.jpg";

import styles from "./styles.module.scss";

export const BonusBanner: React.FC<Props> = memo(({ className }) => {
	return (
		<BannerLayout
			className={classNames(className, styles.banner)}
			title={
				<p className={styles.title}>
					Получайте
					<br />
					баллы за каждое
					<br />
					оформление
				</p>
			}
			description={<p>и тратьте на новый страховой полис</p>}
			background={
				<div className={styles.imageGroup}>
					<Image className={styles.image} src={BackgroundImage} />
					<Image className={classNames(styles.image, styles.mobile)} src={BackgroundImageMobile} />
				</div>
			}
		/>
	);
});

export interface Props extends React.ComponentProps<"div"> {}
