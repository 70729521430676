import React, { memo } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const Align = memo(
	({ children, axis, isAdaptable = false, className, ...rest }: Readonly<Props>) => {
		return (
			<div
				{...rest}
				className={classNames(
					className,
					styles.align,
					axis.includes("x") && styles.x,
					axis.includes("y") && styles.y,
					isAdaptable && styles.adaptable
				)}>
				{children}
			</div>
		);
	}
);

export interface Props extends React.ComponentProps<"div"> {
	axis: ("y" | "x")[] | "x" | "y" | "xy" | "yx";
	isAdaptable?: boolean;
}
