import React, { memo } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const CardContent: React.FC<Props> = memo(({ children, className, ...rest }) => {
	return (
		<div className={classNames(className, styles.cardContent)} {...rest}>
			{children}
		</div>
	);
});

export interface Props extends React.ComponentProps<"div"> {}
