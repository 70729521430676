import React, { useCallback, useEffect, useRef } from "react";
import { Observer } from "mobx-react-lite";
import classNames from "classnames";

import { Tween } from "@components/hoc/Tween";
import { VisibleSwitcher } from "@components/hoc/VisibleSwitcher";

import { Align } from "@components/simple/Align";

import { useModal, useScrollLocker } from "@core/hooks";
import { ModalType } from "@core/types";

import styles from "./styles.module.scss";

const DURATION = 0.25;
const INITIAL_CONTENT_TRANSLATE_Y = -40;

export const Modal: React.FC<Props> = ({
	type,
	children,
	className,
	onHide,
	onShow,
	onShowStart,
	onHideStart,
	isObsessive = false,
	...rest
}) => {
	const modal = useModal();
	const scrollLocker = useScrollLocker("modal");
	const contentRef = useRef<HTMLDivElement>(null);

	const handleAnimationComplete = useCallback(() => {
		if (modal.isShown(type)) {
			scrollLocker.lock();
			modal.handleShow();

			if (onShow) {
				onShow();
			}
		}

		if (modal.isHiding(type)) {
			scrollLocker.unlock();
			modal.handleHide();

			if (onHide) {
				onHide();
			}
		}
	}, [type, modal, scrollLocker, onHide, onShow]);

	const handleAnimationStart = useCallback(() => {
		if (modal.isShown(type) && onShowStart) {
			onShowStart();
		}

		if (modal.isHiding(type) && onHideStart) {
			onHideStart();
		}
	}, [type, modal, onShowStart, onHideStart]);

	const handleModalClick = useCallback(
		(event: any) => {
			if (
				!isObsessive &&
				contentRef.current &&
				modal.isVisible(type) &&
				!contentRef.current.contains(event.target as any)
			) {
				modal.hide();
			}
		},
		[isObsessive, modal, type]
	);

	const handleDocumentKeyDown = useCallback(
		(event: any) => {
			if (!isObsessive && event.key === "Escape" && modal.isVisible(type)) {
				modal.hide();
			}
		},
		[isObsessive, modal, type]
	);

	useEffect(() => {
		document.addEventListener("keydown", handleDocumentKeyDown);

		return () => {
			document.removeEventListener("keydown", handleDocumentKeyDown);
		};
	}, [handleDocumentKeyDown]);

	return (
		<Observer>
			{() => (
				<VisibleSwitcher
					ease='circ.out'
					duration={DURATION}
					isVisible={modal.isOpened(type)}
					onComplete={handleAnimationComplete}
					onStart={handleAnimationStart}>
					<div {...rest} className={styles.modal} onClick={handleModalClick}>
						<Align axis={["x", "y"]}>
							<Tween
								duration={DURATION}
								ease='linear'
								to={modal.isOpened(type) ? { y: 0 } : { y: INITIAL_CONTENT_TRANSLATE_Y }}>
								<div
									ref={contentRef}
									className={classNames(styles.content, className)}
									style={{
										transform: `translate3d(0, ${INITIAL_CONTENT_TRANSLATE_Y}px, 0)`,
										willChange: "transform",
									}}>
									<div className={styles.controls}>
										{!isObsessive ? (
											<button className={styles.close} onClick={modal.hide}>
												×
											</button>
										) : null}
									</div>
									{children}
								</div>
							</Tween>
						</Align>
					</div>
				</VisibleSwitcher>
			)}
		</Observer>
	);
};

export interface Props extends React.ComponentProps<"div"> {
	type: ModalType;
	onHide?: () => void;
	onShow?: () => void;
	onHideStart?: () => void;
	onShowStart?: () => void;
	isObsessive?: boolean;
}
