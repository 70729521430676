import React, { memo } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const Spinner: React.FC<Props> = memo(({ children, className, ...rest }) => {
	return (
		<div className={classNames(styles.spinner, className)} {...rest}>
			<svg className='original' viewBox='0 0 50 50'>
				<circle cx='25' cy='25' r='15' fill='none'></circle>
			</svg>
		</div>
	);
});

export interface Props extends React.ComponentProps<"div"> {}
