import React, { forwardRef, useMemo, useRef } from "react";

import { useInputMask, UseInputMaskOptions } from "@core/hooks";
import { mergeRefs } from "@core/utils";

export const FieldMask = forwardRef<HTMLInputElement, Props>(({ children, ...rest }, ref) => {
	const childrenRef = useRef<HTMLInputElement>(null);
	const mergedRefs = useMemo(() => mergeRefs<HTMLInputElement>(childrenRef, ref), [ref]);

	useInputMask(childrenRef, {
		showMaskOnFocus: false,
		showMaskOnHover: false,
		autoUnmask: true,
		placeholder: "_",
		...rest,
	});

	return (
		<>
			{React.Children.map(children, (child, index) => {
				if (!React.isValidElement(child)) {
					return null;
				}

				return React.cloneElement(child, { ref: mergedRefs });
			})}
		</>
	);
});

export interface Props extends UseInputMaskOptions {
	children?: React.ReactNode;
}
