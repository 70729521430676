import { simplyTransformToPascalCase } from "@core/utils";
import {
	Schema,
	SchemaBase,
	FieldType,
	FieldPropsAssociation,
	WithSet,
	ValidatorRule,
} from "@core/types";

// TODO: Добавить вспомогательные функции для формирования схемы. Например: defaultValue (null | () => any);

/**
 * Преобразует скелетон в схему
 */

export function createStoreSchema<S extends SchemaBase.Store>(object: S) {
	const entries = Object.entries(object);

	return entries.reduce((acc, [key, defaultValue]) => {
		return {
			...acc,
			[key]: {
				value: defaultValue,
				set: function (value) {
					this.value = value;
				},
			},
		};
	}, {} as Schema.Store<S>);
}

export function transformSchemaKeyToActionKey<T extends string>(key: T) {
	return `set${simplyTransformToPascalCase(key)}` as WithSet<T>;
}

export function createStoreSchemaFlat<S extends SchemaBase.Store>(object: S) {
	const entries = Object.entries(object);

	return entries.reduce((acc, [key, defaultValue]: any) => {
		return {
			...acc,
			[key]: defaultValue,
			[`set${simplyTransformToPascalCase(key)}`]: function (value: any) {
				(this as any)[key] = value;
			},
		};
	}, {} as Schema.StoreFlat<S>);
}

/**
 * Фабрика полей
 */
export function fieldSchemaFactory<T extends FieldType>(type: T, checkboxVariety?: boolean) {
	return (props: FieldPropsAssociation[T], rules?: ValidatorRule): Schema.Field<T> => {
		return {
			type: type,
			props: props,
			rules: rules,
			checkboxVariety: checkboxVariety || false,
		};
	};
}

/**
 * Сохраняет тип схемы формы
 */
export function asFormSchema<T extends SchemaBase.Form>(object: T): Schema.Form<T> {
	return object;
}

export const input = fieldSchemaFactory(FieldType.Input);
export const select = fieldSchemaFactory(FieldType.Select);
export const checkbox = fieldSchemaFactory(FieldType.Checkbox, true);
export const inputMask = fieldSchemaFactory(FieldType.InputMask);
export const inputDatepicker = fieldSchemaFactory(FieldType.InputDatepicker);
