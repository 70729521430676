import React, { memo } from "react";
import classNames from "classnames";

import { getIconImage, IconNameKind } from "@assets";

import styles from "./styles.module.scss";

const Icon: React.FC<{ iconName?: IconNameKind; rotate?: number }> = memo(
	({ iconName, rotate }) => {
		return (
			<>
				{iconName ? (
					<span
						className={styles.iconGroup}
						data-button-icon
						style={{ transform: rotate ? `rotate(${rotate}deg)` : undefined }}>
						{getIconImage(iconName)}
					</span>
				) : null}
			</>
		);
	}
);

const Counter: React.FC<{ count?: number }> = memo(({ count }) => {
	return <>{count ? <span className={styles.counter}>{count}</span> : null}</>;
});

export const Button: React.FC<Props> = memo(
	({
		children,
		className,
		iconLeft,
		iconRight,
		counterLeft,
		counterRight,
		iconLeftRotate,
		iconRightRotate,
		type = "button",
		theme = "primary",
		flexible = false,
		fixedLayout = false,
		...rest
	}) => {
		return (
			<button
				type={type}
				className={classNames(
					className,
					styles.button,
					styles[theme],
					flexible && styles.flexible,
					fixedLayout && styles.fixedLayout
				)}
				{...rest}>
				<span className={styles.content}>
					{iconLeft || counterLeft ? (
						<span className={classNames(styles.metaGroup, styles.left)} data-meta-content>
							<Icon iconName={iconLeft} rotate={iconLeftRotate} />
							<Counter count={counterLeft} />
						</span>
					) : null}
					<span className={styles.text}>{children}</span>
					{iconRight || counterRight ? (
						<span className={classNames(styles.metaGroup, styles.right)} data-meta-content>
							<Counter count={counterRight} />
							<Icon iconName={iconRight} rotate={iconRightRotate} />
						</span>
					) : null}
				</span>
			</button>
		);
	}
);

export interface Props extends React.ComponentProps<"button"> {
	iconLeft?: IconNameKind;
	iconRight?: IconNameKind;
	iconLeftRotate?: number;
	iconRightRotate?: number;
	counterLeft?: number;
	counterRight?: number;
	fixedLayout?: boolean;
	flexible?: boolean;
	theme?: "primary" | "secondary" | "outline" | "primary-red";
}
