import gsap from "gsap";

import { tweenConfig } from "@core/config";
import { pickScrollingElement, calculateElementOffset } from "@core/utils";
import { CSSStyleKeyKind } from "@core/types";

/**
 * Преобразует Tween свойства в CSS свойства
 */
export function transformTweenPropsToCssProps({
	to = {},
	from = {},
}: Partial<Record<"to" | "from", gsap.CSSVars>>) {
	const tweenProps = Object.keys({
		...to,
		...from,
	}) as (keyof gsap.CSSVars)[];
	const cssProps = new Set<CSSStyleKeyKind>();

	for (const tweenProp of tweenProps) {
		const targetCssProp = tweenConfig.tweenPropsToCssPropsAssociation.find(({ tweenProps }) =>
			tweenProps.includes(tweenProp)
		);

		if (targetCssProp) {
			targetCssProp.cssProps.forEach((cssProp) => cssProps.add(cssProp));
		}
	}

	if (cssProps.size) {
		return [...cssProps.values()];
	}

	return [];
}

/**
 * Проскролливает до определенной позиции внутри элемента
 * @param element - Элемент, до которого необходимо прокрутить
 * @param options
 * @param options.duration - Время анимации
 * @param options.contextElement - Элемент, внутри которого необходимо выполнить прокрутку @default Document
 * @param options.ease - Название анимации @see https://greensock.com/ease-visualizer/
 */
export function scrollTo(position: number, options: ScrollToOptions) {
	const safeContextElement = pickScrollingElement(options.contextElement || document);
	const scrollTop = {
		value: safeContextElement.scrollTop,
	};

	gsap.to(scrollTop, {
		duration: options.duration,
		value: position,
		ease: options.ease || "linear",
		roundProps: "value",
		onUpdate: () => {
			safeContextElement.scrollTop = scrollTop.value;
		},
	});
}

/**
 * Проскролливает до определенного элемента внутри элемента
 * @param element - Элемент, до которого необходимо прокрутить
 * @param options
 * @param options.duration - Время анимации
 * @param options.contextElement - Элемент, внутри которого необходимо выполнить прокрутку @default Document
 * @param options.ease - Название анимации @see https://greensock.com/ease-visualizer/
 * @param options.offset - Смещение прокрутки
 */
export function scrollToTarget(
	element: HTMLElement | null,
	options: ScrollToOptions & { offset?: number }
) {
	const safeContextElement = pickScrollingElement(options.contextElement || document);

	if (element) {
		// Определяем скролл внутри контекстного элемента
		const contextOffsetTop =
			safeContextElement === document.scrollingElement
				? 0
				: calculateElementOffset(safeContextElement).top;
		const targetOffsetTop = calculateElementOffset(element).top;
		// Находим необходимый скролл до определенного элемента
		const targetScrollTop = targetOffsetTop - contextOffsetTop + (options.offset || 0);

		scrollTo(targetScrollTop, {
			...options,
			contextElement: safeContextElement,
		});
	}
}

interface ScrollToOptions {
	duration: number;
	contextElement?: HTMLElement | null;
	ease?: string;
}
