import React, { useCallback, useEffect, useMemo } from "react";

import { validatorHelpers } from "@core/helpers";
import { useLocalStore, useLocalStoreFlat } from "@core/hooks";
import { reduceToObject, mergeCallbacks } from "@core/utils";
import { FieldPropsAssociation, Schema, SchemaBase } from "@core/types";

export function useForm<T extends SchemaBase.Form, Name extends keyof T>(
	schema: Schema.Form<T>,
	{ initialValues = {}, onSomeFieldChange }: Options<T> = {}
) {
	const nameList = useMemo(() => {
		return Object.keys(schema) as Name[];
	}, [schema]);

	const reduceNameList = useCallback(
		<R>(callback: (name: Name, index: number) => R) => {
			return reduceToObject(nameList, callback, true) as Record<Name, R>;
		},
		[nameList]
	);

	const defaultErrors = useMemo(() => {
		return reduceNameList(() => "");
	}, [reduceNameList]);

	const defaultProps = useMemo(() => {
		return reduceNameList((name) => schema[name].props || {});
	}, [schema, reduceNameList]);

	const defaultValues = useMemo(() => {
		return reduceNameList((name) => initialValues[name] || "") as Record<Name, string>;
	}, [initialValues, reduceNameList]);

	const rules = useMemo(() => {
		return reduceNameList((name) => schema[name].rules);
	}, [schema, reduceNameList]);

	const localStore = useLocalStoreFlat({ disabled: false });
	const valueStore = useLocalStore(defaultValues);
	const errorStore = useLocalStore(defaultErrors);
	const propsStore = useLocalStore(defaultProps);

	const check = useMemo(() => {
		return validatorHelpers.validator.compile(rules);
	}, [rules]);

	const setProps = useCallback(
		<N extends Name, P extends Props<T, N>>(name: N, props: ((prevProps: P) => P) | P) => {
			const prevProps = propsStore[name].value;
			const nextProps = typeof props === "function" ? (props as any)(prevProps) : props;

			propsStore[name].set(nextProps);
		},
		[propsStore]
	);

	const setProp = useCallback(
		<N extends Name, P extends Props<T, N>, K extends keyof P>(
			name: N,
			propName: K,
			value: ((prevValue: P[K]) => P[K]) | P[K]
		) => {
			const prevProp = propsStore[name].value[propName as any];
			const nextProp = typeof value === "function" ? (value as any)(prevProp) : value;

			setProps(name, (prevProps) => ({ ...prevProps, [propName]: nextProp }));
		},
		[propsStore, setProps]
	);

	const setValues = useCallback(
		(values: Partial<Record<Name, string>>) => {
			(Object.entries(values) as [Name, string][]).forEach(([name, value]) => {
				valueStore[name].set(value);
			});
		},
		[valueStore]
	);

	const setValue = useCallback(
		(name: Name, value: ((prevValue: string) => string) | string) => {
			const prevValue = valueStore[name].value;
			const nextValue = (typeof value === "function" ? (value as any)(prevValue) : value) as string;

			setValues({ [name]: nextValue } as any);
		},
		[valueStore, setValues]
	);

	const getValue = useCallback(
		(name: Name) => {
			return valueStore[name].value;
		},
		[valueStore]
	);

	const getValues = useCallback(() => {
		return reduceToObject(nameList, (name) => getValue(name));
	}, [getValue, nameList]);

	const getProp = useCallback(
		<N extends Name, P extends Props<T, N>, K extends keyof P>(name: N, propName: K) => {
			return propsStore[name].value[propName as any];
		},
		[propsStore]
	);

	const setErrors = useCallback(
		(errors: Partial<Record<Name, string>>) => {
			(Object.entries(errors) as [Name, string][]).forEach(([name, value]) => {
				errorStore[name].set(value);
			});
		},
		[errorStore]
	);

	const setError = useCallback(
		(name: Name, error: ((prevError: string) => string) | string) => {
			const prevError = errorStore[name].value;
			const nextError = (typeof error === "function" ? (error as any)(prevError) : error) as string;

			setErrors({ [name]: nextError } as any);
		},
		[errorStore, setErrors]
	);

	const createChangeHandler = useCallback(
		(name: Name) => (event: React.ChangeEvent<any>) => {
			let value = event.target.value || "";

			if (schema[name].checkboxVariety) {
				value = (event.target as any).checked ? "true" : "";
			}

			setValue(name, value);

			if (errorStore[name].value) {
				errorStore[name].set("");
			}

			if (onSomeFieldChange) {
				onSomeFieldChange(name, value, setValue as any);
			}
		},
		[errorStore, schema, setValue, onSomeFieldChange]
	);

	const bind = useCallback(
		<N extends Name>(name: N, additionalProps: Partial<Props<T, N>> = {}) => {
			const value = valueStore[name].value;
			const props = propsStore[name].value;
			const error = errorStore[name].value;
			const mainProps = schema[name].checkboxVariety
				? {
						checked: value === "true",
				  }
				: { value: value };

			return {
				...props,
				...(additionalProps as any),
				...mainProps,
				errorMessage: error,
				fieldType: schema[name].type,
				name: props.name || name,
				value: valueStore[name].value as string,
				onChange: mergeCallbacks(createChangeHandler(name), additionalProps.onChange) as any,
				disabled: (props.disabled || localStore.disabled) as boolean,
			};
		},
		[valueStore, propsStore, errorStore, schema, createChangeHandler, localStore]
	);

	const isDisabled = useCallback(() => {
		return localStore.disabled;
	}, [localStore]);

	const setDisabled = useCallback(
		(disabled: boolean) => {
			localStore.setDisabled(disabled);
		},
		[localStore]
	);

	const validate = useCallback(
		(name?: Name) => {
			const errors = validatorHelpers.getErrors(check, getValues() as any);
			const targetErrors = name ? (errors[name] ? { [name]: errors[name] } : {}) : errors;
			const keyLength = Object.keys(targetErrors).filter(
				(fieldName) => !name || name === fieldName
			).length;

			if (keyLength > 0) {
				setErrors(targetErrors);

				return false;
			}

			return true;
		},
		[check, getValues, setErrors]
	);

	const isValid = useCallback(() => {
		return validatorHelpers.isValuesValid(check, getValues() as any);
	}, [check, getValues]);

	const clearErrors = useCallback(() => {
		setErrors(reduceNameList(() => ""));
	}, [reduceNameList, setErrors]);

	const clearValues = useCallback(() => {
		setValues(reduceNameList(() => ""));
	}, [reduceNameList, setValues]);

	const clear = useCallback(() => {
		clearValues();
		clearErrors();
	}, [clearValues, clearErrors]);

	useEffect(() => {
		setValues(defaultValues);
	}, [defaultValues, setValues]);

	useEffect(() => {
		setErrors(defaultErrors);
	}, [defaultErrors, setErrors]);

	useEffect(() => {
		nameList.forEach((name) => {
			setProps(name, defaultProps[name]);
		});
	}, [nameList, defaultProps, setProps]);

	return {
		setProp,
		setProps,
		setValue,
		setValues,
		setError,
		setErrors,
		getProp,
		getValue,
		getValues,
		isDisabled,
		setDisabled,
		validate,
		clear,
		clearValues,
		clearErrors,
		isValid,
		bind,
	};
}

type Props<T extends SchemaBase.Form, F extends keyof T> = FieldPropsAssociation[T[F]["type"]];
interface Options<T> {
	initialValues?: Partial<Record<keyof T, string>>;
	onSomeFieldChange?: (
		name: keyof T,
		value: string,
		setValue: (name: keyof T, value: string) => void
	) => void;
}
