import React from "react";

export const questions: { answer: React.ReactNode; question: React.ReactNode }[] = [
	{
		question: "В какой страховой компании у меня будет застрахована ипотека",
		answer: (
			<>
				Вы будете застрахованы в компании АО «АльфаСтрахование».
				<br />
				АльфаСтрахование - надежный партнер, который предлагает инновационный сервис и выгодные условия
				по страхованию.
			</>
		),
	},
	{
		question: "Какие преимущества у компании «АльфаСтрахование»?",
		answer: (
			<>
				«АльфаСтрахование» — крупнейшая частная страховая компания России , больше 20 лет работает на
				рынке страхования и имеет высокий рейтинг надежности (ruAA+).
				<ul>
					<li>Гибкий подход к оценке рисков.</li>
					<li>Быстрое рассмотрение заявки.</li>
					<li>Опытные сотрудники ответят на Ваши вопросы и произведут расчет стоимости страховки.</li>
					<li>Больше 200 офисов в России.</li>
				</ul>
				АльфаСтрахование имеет аккредитацию во многих банках России
			</>
		),
	},
	{
		question: "В каком случае предоставляется скидка 30%?",
		answer: (
			<>
				Вы можете оформить полис онлайн и получить скидку 30% от текущей стоимости, если воспользуетесь
				нашим сервисом. Предложение распространяется как на страхование жизни, так и на страхование
				имущества и титула.
			</>
		),
	},
	{
		question: "Для кредитов каких Банков можно оформить полис страхования онлайн?",
		answer: (
			<>
				<ul>
					<li>ПАО Сбербанк</li>
				</ul>
				<br />
				АО АльфаСтрахование имеет аккредитацию во многих банках, в том числе в ПАО Сбербанк
			</>
		),
	},
	{
		question: "Какие риски застрахованы и что относится к страховым случаям?",
		answer: (
			<>
				Договор страхования соответствует требованиям банка-кредитора по страховому покрытию: набору
				страховых рисков и событий (страховых случаев).
				<br />
				<br />
				<b>Страхование жизни и здоровья заемщика</b>
				<br />
				<br />
				Страховым случаем является смерть или инвалидность 1 или 2 группы застрахованного лица
				<br />
				<br />
				<b>Страхование имущества от гибели и повреждения</b>
				<br />
				<br />
				Страховым случаем являются события:
				<ul>
					<li>Пожар</li>
					<li>Взрыв</li>
					<li>Залив</li>
					<li>
						Стихийное бедствие (буря, тайфун, цунами, наводнение, землетрясение, паводок, извержение
						вулкана и др.)
					</li>
					<li>Падение на застрахованный объект летательных аппаратов и их частей</li>
					<li>Наезда автотранспорта</li>
					<li>Противоправные действия третьих лиц</li>
				</ul>
				<br />
				<b>Страхование титула (права собственности)</b>
				<br />
				<br />
				Страховым случаем является утрата имущества в результате прекращения права собственности по
				причинам, не зависящим от страхователя, на основании вступившего в законную силу решения суда
				как в период действия договора, так и после его окончания, если иск был подан в период действия
				договора.
			</>
		),
	},
	{
		question: "Что не является страховым случаем?",
		answer: (
			<>
				Страховая компания не покрывает ущерб, причиненный в результате:
				<ul>
					<li>Ядерного взрыва</li>
					<li>Воздействия радиации</li>
					<li>Войны, интервенции</li>
					<li>
						Совершения страхователем, застрахованным лицом, выгодоприобретателем умышленного преступления,
						повлекшего за собой наступление страхового случая
					</li>
					<li>Умысла страхователя или лиц, которые действуют по его поручению</li>
					<li>Обращения взыскания на застрахованное имущество по обязательствам страхователя</li>
					<li>
						Изъятия имущества у страхователя государственными или муниципальными органами с компенсацией
						его стоимости
					</li>
					<li>
						Употребления наркотических, токсических, сильнодействующих, психотропных веществ и других
						веществ без предписания врача (данное исключение не распространяется на события, произошедшие
						не по вине застрахованного лица);
					</li>
					<li>Обман, предоставление заведомо ложных сведений страховщику о своем здоровье, имуществе</li>
				</ul>
			</>
		),
	},
	{
		question: "Как формируется стоимость страхового полиса?",
		answer: (
			<>
				Стоимость страхового полиса зависит от страхового покрытия (рисков, которые застрахованы,
				страховых событий), возраста заемщика, суммы кредита. По мере снижения суммы кредита меняется
				стоимость страхового полиса.
			</>
		),
	},
	{
		question: "Для чего нужно страхование по ипотечному кредиту?",
		answer: (
			<>
				Ипотечный кредит предусматривает выплаты в течение нескольких десятков лет, а жизнь заемщика
				сопряжена с различными рисками.
				<br />
				<br />
				Страхование защищает интересы заемщика, банка, который выдал кредит, и родственников заемщика в
				случае его смерти. Заемщик будет застрахован от таких рисков, как утрата трудоспособности при
				установлении инвалидности 1 и 2 группы, гибель или повреждение заложенного имущества, лишение
				судом права собственности на заложенное имущество.
				<br />
				<br />
				Страховщик возместит сумму кредита банку или родственникам заемщика при наступлении его смерти,
				тем самым освободит родственников от обязательств перед банком.
			</>
		),
	},
	{
		question: "На какой период заключается полис страхования?",
		answer: (
			<>
				Срок действия страхового полиса соответствует сроку действия кредитного договора. При полном
				погашении кредита договор можно прекратить.
			</>
		),
	},
	{
		question: "Что делать, если в полисе допущена ошибка?",
		answer: (
			<>
				Для того, чтобы внести изменения в оплаченный полис страхования Вам необходимо выбрать одно из
				действий:
				<br />
				<ul>
					<li>
						Позвонить по номеру телефона <a href='tel:+79091525600'>8 909 152 56 00</a> и сообщить ФИО,
						номер договора страхования, место приобретения, указав, что полис страхования был оформлен на
						сайте{" "}
						<a href='https://alfaipoteka.alfastrah.ru' rel='noopener noreferrer' target='_blank'>
							alfaipoteka.alfastrah.ru
						</a>
						.
					</li>
					<li>
						Написать на общий почтовый ящик info@alfastrah-me.ru письмо с указанием вашего ФИО, номера
						полиса, указать причину, по которой необходимо внести изменения в полис, приложить документы,
						обосновывающие необходимость изменений
					</li>
				</ul>
			</>
		),
	},
	{
		question: "Возможно ли изменить условия договора?",
		answer: (
			<>
				Да, по соглашению сторон. О внесении любых изменений в договор страхования стороны обязаны
				уведомить выгодоприобретателя — банк.
			</>
		),
	},
	{
		question: "Какие документы мне необходимо предоставить в Банк на ипотечную сделку?",
		answer: (
			<>
				После оформления и оплаты полиса на Вашу электронную почту будет направлен оригинал полиса
				страхования, заявление на страхование, чек, подтверждающий факт оплаты страхового полиса. На
				ипотечную сделку потребуется: полис страхования, чек подтверждающий оплату полиса
			</>
		),
	},
	{
		question: "Можно ли купить полис от АльфаСтрахование, когда я уже нахожусь в Банке на сделке?",
		answer: (
			<>
				Да, вы можете купить полис, но в некоторых случаях это может занять больше времени, чем вы
				рассчитываете, поэтому рекомендуем на менее чем за день до получения кредита начать оформление
				страховки.
			</>
		),
	},
	{
		question: "Придется ли мне согласовывать полис с Банком?",
		answer: <>Полис ипотечного страхования не нужно согласовывать с Банком</>,
	},
	{
		question: "Придется ли мне предоставлять в АльфаСтрахование дополнительные документы?",
		answer: (
			<>
				АльфаСтрахование в индивидуальных случаях вправе запросить у Вас документы, касательно сделки по
				недвижимости
			</>
		),
	},
	{
		question: "Как связаться с АльфаСтрахование?",
		answer: (
			<>
				С нами можно связаться по телефону <a href='tel:+79091525600'>8 909 152 56 00</a> или по
				электронной почте <a href='mailto:info@alfastrah-me.ru'>info@alfastrah-me.ru</a>.
			</>
		),
	},
	{
		question: "Что делать, если произошел страховой случай?",
		answer: (
			<>
				Если у Вас произошел страховой случай, вам необходимо оставить обращение по телефону{" "}
				<a href='tel:+79091525600'>8 909 152 56 00</a>, далее следовать инструкции оператора
				call-центра.
			</>
		),
	},
];
