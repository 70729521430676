import { Switch, Route } from "react-router-dom";

import { Main } from "@pages/Main";
// import { Issue } from "@pages/Issue";
// import {Product} from "../pages/Product";

export const Routes: React.FC = () => {
	return (
		<Switch>
			{/* <Route path='/issue'>
				<Issue />
			</Route> */}
			<Route path='/'>
				<Main />
			</Route>
		</Switch>
	);
};
