import { normalizePhoneNumber } from "@core/utils";

export const phoneNumber = "89091525600";
export const whatsAppLink = "https://api.whatsapp.com/send?phone=79091523300";
export const telegramLink = "https://t-do.ru/ibereg_official";

function asPhoneNumberFormatters<T extends Record<string, (phoneNumber: string) => string>>(
	object: T
) {
	return object;
}

export const phoneNumberFormatters = asPhoneNumberFormatters({
	withLeadingPlus: (phoneNumber: string) => `+7${normalizePhoneNumber(phoneNumber)}`,
	withLeadingSeven: (phoneNumber: string) => `7${normalizePhoneNumber(phoneNumber)}`,
	withLeadingEight: (phoneNumber: string) => `8${normalizePhoneNumber(phoneNumber)}`,
});

function asPhoneNumberFormatPieces<
	T extends { [key: string]: { length: number; delimiter: string }[] }
>(object: T) {
	return object;
}

export const phoneNumberFormatsPieces = asPhoneNumberFormatPieces({
	default: [
		{ length: 3, delimiter: " " },
		{ length: 3, delimiter: "-" },
		{ length: 2, delimiter: "-" },
		{ length: 2, delimiter: "" },
	],
});
