import React, { forwardRef } from "react";

import { Input } from "@components/ui/Input";
import { Select } from "@components/ui/Select";
import { Checkbox } from "@components/ui/Checkbox";
import { InputMask } from "@components/ui/InputMask";
import { InputDatepicker } from "@components/ui/InputDatepicker";

import { FieldType, FieldPropsAssociation } from "@core/types";

export const Field = forwardRef(
	<T extends FieldType>(
		{ fieldType, errorMessage, ...rest }: Props<T>,
		ref: React.Ref<HTMLElement>
	) => {
		const untypedRef = ref as any;
		const untypedRest = rest as any;

		switch (fieldType) {
			case FieldType.Input:
				return <Input ref={untypedRef} {...untypedRest} />;
			case FieldType.Select:
				return <Select ref={untypedRef} {...untypedRest} />;
			case FieldType.InputMask:
				return <InputMask ref={untypedRef} {...untypedRest} />;
			case FieldType.Checkbox:
				return <Checkbox ref={untypedRef} {...untypedRest} />;
			case FieldType.InputDatepicker:
				return <InputDatepicker ref={untypedRef} {...untypedRest} />;
			default:
				return null;
		}
	}
);

export type Props<T extends FieldType> = {
	fieldType: T;
	errorMessage?: string;
} & FieldPropsAssociation[T];
