import React, { forwardRef } from "react";
import classNames from "classnames";

import { Field, Props as FieldProps } from "@components/smart/Field";

import { FieldType } from "@core/types";

import styles from "./styles.module.scss";

export const ErrorField = forwardRef(
	<T extends FieldType>(
		{ errorMessage, prompt, className, ...rest }: Props<T>,
		ref: React.Ref<HTMLElement>
	) => {
		return (
			<div className={styles.errorField}>
				<Field
					ref={ref}
					className={classNames(className, styles.field, errorMessage && styles.error, "hi")}
					{...(rest as any)}
				/>
				{errorMessage || prompt ? (
					<p
						className={classNames(styles.message, !errorMessage && prompt ? styles.prompt : null)}
						data-error-message>
						{errorMessage || prompt}
					</p>
				) : (
					""
				)}
			</div>
		);
	}
);

export type Props<T extends FieldType> = FieldProps<T> & {
	errorMessage?: string;
	className?: string;
	prompt?: string;
};
