import React, { forwardRef } from "react";
import classNames from "classnames";

import { CheckboxBase, Props as CheckboxBaseProps } from "@components/ui/CheckboxBase";

import { getIconImage } from "@assets";

import styles from "./styles.module.scss";

export const Checkbox = forwardRef<HTMLInputElement, Props>(
	({ label, className, labelPosition = "right", ...rest }, ref) => {
		return (
			<CheckboxBase
				ref={ref as any}
				className={classNames(styles.checkbox, styles[`labelPosition-${labelPosition}`], className)}
				{...rest}>
				<span className={styles.button}>
					<span className={styles.iconGroup}>{getIconImage("check")}</span>
				</span>
				{label ? (
					<span className={classNames(styles.label, "noselect")} data-label>
						{label}
					</span>
				) : null}
			</CheckboxBase>
		);
	}
);

export interface Props extends CheckboxBaseProps {
	label?: string;
	labelPosition?: "right" | "left";
}
