import React, { forwardRef } from "react";

import { FieldMask, Props as FieldMaskProps } from "@components/hoc/FieldMask";

import { Input, Props as InputProps } from "@components/ui/Input";

export const InputMask = forwardRef<HTMLInputElement, Props>(({ maskOptions, ...rest }, ref) => {
	return (
		<FieldMask ref={ref} {...maskOptions}>
			<Input {...rest} />
		</FieldMask>
	);
});

export interface Props extends Omit<InputProps, "ref"> {
	maskOptions: Omit<FieldMaskProps, "children">;
}
