import type { AxiosResponse } from "axios";
import type { ValidationRuleObject } from "fastest-validator";

import type { Props as InputProps } from "@components/ui/Input";
import type { Props as SelectProps } from "@components/ui/Select";
import type { Props as CheckboxProps } from "@components/ui/Checkbox";
import type { Props as InputMaskProps } from "@components/ui/InputMask";
import type { Props as InputDatepickerProps } from "@components/ui/InputDatepicker";

export enum FieldType {
	Input,
	Select,
	Checkbox,
	InputMask,
	InputDatepicker,
}

export declare namespace FieldProps {
	export type Input = InputProps;
	export type Select = SelectProps;
	export type Checkbox = CheckboxProps;
	export type InputMask = InputMaskProps;
	export type InputDatepicker = InputDatepickerProps;
}

export declare namespace FieldProviderProps {
	export type InputMask = InputMaskProps;
}

export type DatepickerRangeTypeKind = "days" | "months" | "years";

export type FieldPropsAssociation = {
	[FieldType.Input]: Omit<FieldProps.Input, "ref">;
	[FieldType.Select]: Omit<FieldProps.Select, "ref">;
	[FieldType.Checkbox]: Omit<FieldProps.Checkbox, "ref">;
	[FieldType.InputMask]: Omit<FieldProps.InputMask, "ref">;
	[FieldType.InputDatepicker]: Omit<FieldProps.InputDatepicker, "ref">;
};

export declare namespace API {
	export namespace Service {
		export type Function<T extends API.DataLessResponse<any>, U extends any[] = any[]> = (
			...params: U
		) => Promise<AxiosResponse<T>>;
	}

	export type Response<T> = DataLessResponse<T> & T;

	export type DataLessResponse<T = any> = {};
}

export declare namespace Schema {
	export type Field<T extends FieldType> = {
		type: T;
		props: FieldPropsAssociation[T];
		rules?: ValidatorRule;
		checkboxVariety: boolean;
	};

	export type Form<F extends SchemaBase.Form> = {
		[K in keyof F]: Schema.Field<F[K]["type"]>;
	};

	export type PartialFieldsObject<T> = Partial<Record<Take.FromFormSchema.FieldNameKind<T>, string>>;

	export type Store<F extends SchemaBase.Store> = {
		[K in keyof F]: {
			value: F[K];
			set: (value: F[K]) => void;
		};
	};

	export type StoreFlat<F extends SchemaBase.Store> = F & {
		[K in WithSet<CamelCaseToPascalCase<keyof F>>]: (
			value: F[PascalCaseToCamelCase<WithoutSet<K>>]
		) => void;
	};
}

export declare namespace SchemaBase {
	export type Store = { [s: string]: any };
	export type Form = {
		[s: string]: Schema.Field<any>;
	};
}

export declare namespace Take {
	export type FromStoreSchema<T> = T extends Schema.Store<infer R> ? R : never;
	export namespace FromFormSchema {
		export type FieldNameKind<T> = T extends Schema.Form<infer R> ? keyof R : never;
	}
	export namespace FromServiceFunction {
		export type Response<T> = T extends API.Service.Function<infer R> ? R : never;
		export type ResponseModel<T> = T extends API.Service.Function<API.DataLessResponse<infer R>>
			? R
			: never;
	}
}

export type CSSStyleKeyKind = keyof CSSStyleDeclaration;

export type CamelCaseToPascalCase<T extends string> = T extends `${infer FirstLetter}${infer _Rest}`
	? `${Capitalize<FirstLetter>}${_Rest}`
	: T;
export type PascalCaseToCamelCase<T extends string> =
	T extends `${infer FirstLetter}${infer SecondLetter}${infer _Rest}`
		? SecondLetter extends Capitalize<SecondLetter>
			? `${FirstLetter}${SecondLetter}${_Rest}`
			: `${Uncapitalize<FirstLetter>}${SecondLetter}${_Rest}`
		: T;

export type WithSet<T extends string> = `set${T}`;
export type WithoutSet<T extends string> = T extends `set${infer _Rest}` ? _Rest : T;

export type ValidatorRule = ValidationRuleObject | string | string[];

export enum ModalType {
	OrderBeingProcessed,
	NeedManagerHelp,
}

export enum ModalStateType {
	Visible,
	Hidden,
	Hiding,
	Shown,
	NeedToShow,
}
