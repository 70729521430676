import React, { memo } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const BannerLayout: React.FC<Props> = memo(
	({ title, description, background, className, children, ...rest }) => {
		return (
			<div {...rest} className={classNames(className, "banner", styles.bannerLayout)}>
				<div className={styles.background}>{background}</div>
				<div className={styles.foreground}>
					<div className={styles.title}>{title}</div>
					<div className={styles.description}>{description}</div>
				</div>
			</div>
		);
	}
);

export interface Props extends Omit<React.ComponentProps<"div">, "title"> {
	title: React.ReactNode;
	description: React.ReactNode;
	background: React.ReactNode;
}
