import { ReactComponent as WhatsApp } from "./icons/whatsapp.svg";
import { ReactComponent as Telegram } from "./icons/telegram.svg";
import { ReactComponent as Rouble } from "./icons/rouble.svg";
import { ReactComponent as PostAdd } from "./icons/postAdd.svg";
import { ReactComponent as CreditCard } from "./icons/creditCard.svg";
import { ReactComponent as Calculate } from "./icons/calculate.svg";
import { ReactComponent as AttachEmail } from "./icons/attachEmail.svg";
import { ReactComponent as Check } from "./icons/check.svg";
import { ReactComponent as ChevronDown } from "./icons/chevronDown.svg";
import { ReactComponent as Gift } from "./icons/gift.svg";

const icons = {
	whatsApp: <WhatsApp />,
	telegram: <Telegram />,
	rouble: <Rouble />,
	postAdd: <PostAdd />,
	creditCard: <CreditCard />,
	calculate: <Calculate />,
	attachEmail: <AttachEmail />,
	check: <Check />,
	chevronDown: <ChevronDown />,
	gift: <Gift />,
};

export function getIconImage(iconName: IconNameKind) {
	return icons[iconName];
}

export type IconNameKind = keyof typeof icons;
