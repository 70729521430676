import React, { memo } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const Image: React.FC<Props> = memo(
	({ sources = [], alt = "", src = "", className, ...rest }) => {
		return (
			<div className={classNames(styles.image, "noselect", className)}>
				<picture>
					{sources.length > 0
						? sources.map(({ src, media }, index) => (
								<source key={`image-source-${index}`} media={media} srcSet={src} />
						  ))
						: null}
					<img alt={alt} src={src} {...rest} />
				</picture>
			</div>
		);
	}
);

export interface Props extends React.ComponentProps<"img"> {
	sources?: {
		src: string;
		media?: string;
	}[];
}
