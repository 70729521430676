import React, { forwardRef } from "react";

import { FieldDatepicker, Props as FieldDatepickerProps } from "@components/hoc/FieldDatepicker";

import { InputMask, Props as InputMaskProps } from "@components/ui/InputMask";

export const InputDatepicker = forwardRef<HTMLInputElement, Props>(
	({ datepickerOptions, ...rest }, ref) => {
		return (
			<FieldDatepicker ref={ref} {...datepickerOptions}>
				<InputMask {...rest} />
			</FieldDatepicker>
		);
	}
);

export interface Props extends Omit<InputMaskProps, "ref"> {
	datepickerOptions: Omit<FieldDatepickerProps, "children">;
}
