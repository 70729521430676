import { useCallback, useEffect } from "react";

import { useLocalStoreFlat } from "@core/hooks";
import { API, Take } from "@core/types";

/**
 *	Данный хук позволяет контроллировать состояние вызова сериса
 * @param service - Сервис-функция
 * @param options
 * @param options.isPendingAfterMount - Необходимо ли сделать так, чтобы при монтировании, сервис сразу же был в режиме ожидание
 * @param options.emitHTTPErrors - Игнорировать HTTP ошикби
 * @returns
 */
export function useAPI<
	F extends API.Service.Function<API.DataLessResponse<any>>,
	R extends Take.FromServiceFunction.Response<F>,
	P extends Parameters<F>
>(service: F, { isPendingAfterMount = false, emitHTTPErrors = false }: Options = {}) {
	const localStore = useLocalStoreFlat({ isPending: isPendingAfterMount });

	/**
	 *	Вызывает функцию-сервис с необходимыми для неё аргументами и возвращает промис, в котором хранится результат выполнения запроса
	 */
	const call = useCallback(
		async (...params: P): Promise<R> => {
			localStore.setIsPending(true);

			try {
				const response = await service(...params);
				const data = response.data;

				return data as R;
			} catch (error) {
				if (emitHTTPErrors === false) {
					console.error(error);
				}

				throw error;
			} finally {
				localStore.setIsPending(false);
			}
		},
		[localStore, service, emitHTTPErrors]
	);

	/**
	 * Возвращает true, если запрос находится в режиме ожидании
	 */
	const isPending = useCallback(() => {
		return localStore.isPending;
	}, [localStore]);

	useEffect(() => {
		localStore.setIsPending(isPendingAfterMount);
	}, [localStore, isPendingAfterMount]);

	return {
		call,
		isPending,
	};
}

export interface Options {
	isPendingAfterMount?: boolean;
	emitHTTPErrors?: boolean;
}
