import React, { memo } from "react";
import classNames from "classnames";

import { BannerLayout } from "@components/ordinary/BannerLayout";

import { Image } from "@components/ui/Image";

import BackgroundImage from "@assets/images/banner-1.png";

import styles from "./styles.module.scss";

export const BrandingBanner: React.FC<Props> = memo(({ className }) => {
	return (
		<BannerLayout
			className={classNames(className, styles.banner)}
			title={
				<p className={styles.title}>
					<span className={styles.highlighted}>Берéг</span> — онлайн
					<br />
					агрегатор
				</p>
			}
			description={
				<p>
					Самый быстрый, удобный
					<br />и выгодный способ оформить
					<br />
					страховой полис
				</p>
			}
			background={
				<div className={styles.background}>
					<div className={styles.imageGroup}>
						<Image className={styles.image} src={BackgroundImage} />
					</div>
				</div>
			}
		/>
	);
});

export interface Props extends React.ComponentProps<"div"> {}
