import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Dayjs, UnitType } from "dayjs";

import { useResizeObserver, useLocalStoreFlat } from "@core/hooks";
import { dateHelpers } from "@core/helpers";
import { pickScrollingElement } from "@core/utils";
import { DatepickerRangeTypeKind } from "@core/types";

export function useDatepicker({
	ref,
	date,
	format,
	maxDate,
	minDate,
	daysLimit = 42,
	yearsLimit = 10,
	rangeType = "days",
}: Options) {
	const spareRef = useRef<HTMLDivElement>(null);
	const targetRef = useMemo(() => ref || spareRef, [ref, spareRef]);
	const currentDate = useMemo(() => dateHelpers.getCurrentDate(), []);
	const resizeObserver = useResizeObserver({
		ref: targetRef,
		safelyMode: true,
		rectDetectorType: "boundingClientRect",
	});

	const selectedDate = useMemo(
		() => dateHelpers.conditionalParse(date, format)?.clone(),
		[date, format]
	);
	const viewedDate = useMemo(
		() => (selectedDate || currentDate).clone(),
		[currentDate, selectedDate]
	);

	const localStore = useLocalStoreFlat({
		viewedDate: viewedDate,
		selectedDate: selectedDate,
		rangeType: rangeType,
	});

	const setViewedDate = useCallback(
		(date: Dayjs) => {
			localStore.setViewedDate(date);
		},
		[localStore]
	);

	const setSelectedDate = useCallback(
		(date: Dayjs) => {
			localStore.setSelectedDate(date);
		},
		[localStore]
	);

	const getRangeType = useCallback(() => {
		return localStore.rangeType;
	}, [localStore]);

	const getUnitTypeRelativeRangeType = useCallback((): UnitType => {
		switch (localStore.rangeType) {
			case "days":
				return "date";
			case "months":
				return "month";
			default:
				return "year";
		}
	}, [localStore]);

	const addMetadata = useCallback(
		(date: dateHelpers.DateWithMetadata[]) => {
			return date.map((date) => ({
				...date,
				isSelected: date.date.isSame(localStore.selectedDate || "", getUnitTypeRelativeRangeType()),
				isAccepted:
					localStore.rangeType === "days"
						? ((minDate && (date.date.isAfter(minDate) || date.date.isSame(minDate, "date"))) ||
								!minDate) &&
						  ((maxDate && (date.date.isBefore(maxDate) || date.date.isSame(maxDate, "date"))) ||
								!maxDate)
						: true,
			}));
		},
		[localStore, getUnitTypeRelativeRangeType, minDate, maxDate]
	);

	const getRange = useCallback(() => {
		switch (localStore.rangeType) {
			case "days":
				return dateHelpers.getDaysRangeWithBounds(localStore.viewedDate, daysLimit);
			case "months":
				return dateHelpers.getMonthsRangeWithBounds(localStore.viewedDate);

			default:
				return dateHelpers.getYearsRangeWithBounds(localStore.viewedDate, yearsLimit);
		}
	}, [localStore, daysLimit, yearsLimit]);

	const getRangeWithMetadata = useCallback(() => {
		return addMetadata(getRange());
	}, [addMetadata, getRange]);

	const getViewedMonthName = useCallback(() => {
		return dateHelpers.getMonthName(localStore.viewedDate);
	}, [localStore]);

	const getViewedYearNumber = useCallback(() => {
		return localStore.viewedDate.get("year");
	}, [localStore]);

	const getViewedDateLabelsBounds = useCallback(() => {
		const range = getRange();

		return [range[0].label, range[range.length - 1].label];
	}, [getRange]);

	const getSlideDataRelativeRangeType = useCallback((): [number, UnitType] => {
		switch (localStore.rangeType) {
			case "days":
				return [1, "month"];
			case "months":
				return [1, "year"];
			default:
				return [yearsLimit, "year"];
		}
	}, [localStore, yearsLimit]);

	const slide = useCallback(
		(type: "next" | "prev") => {
			const slideData = getSlideDataRelativeRangeType();
			const viewedDate = localStore.viewedDate.clone();
			const newViewedDate =
				type === "next" ? viewedDate.add(...slideData) : viewedDate.subtract(...slideData);

			localStore.setViewedDate(newViewedDate);
		},
		[localStore, getSlideDataRelativeRangeType]
	);

	const switchRangeTypeStepByStep = useCallback(
		(direction: "top" | "down") => {
			const currentRangeType = localStore.rangeType;
			let newRangeType: DatepickerRangeTypeKind;

			if (direction === "top") {
				switch (currentRangeType) {
					case "days":
						newRangeType = "months";
						break;
					default:
						newRangeType = "years";
				}
			} else {
				switch (currentRangeType) {
					case "years":
						newRangeType = "months";
						break;
					default:
						newRangeType = "days";
				}
			}

			localStore.setRangeType(newRangeType);
		},
		[localStore]
	);

	const calculatePositionDirection = useCallback(
		(targetPosition: { top: number }) => {
			const windowHeight = window.innerHeight;
			const datepickerSize = resizeObserver.getSize();
			const mod = (targetPosition.top - pickScrollingElement(document).scrollTop) % windowHeight;

			return mod - datepickerSize.height < 0 ? "bottom" : "top";
		},
		[resizeObserver]
	);

	const calculatePosition = useCallback(
		(targetSize: { height: number }, targetPosition: { left: number; top: number }) => {
			const datepickerSize = resizeObserver.getSize();
			const positionDirection = calculatePositionDirection(targetPosition);

			const toTop = targetPosition.top - datepickerSize.height;
			const toBottom = targetPosition.top + targetSize.height;

			return {
				top: positionDirection === "bottom" ? toBottom : toTop,
				left: targetPosition.left,
			};
		},
		[calculatePositionDirection, resizeObserver]
	);

	useEffect(() => {
		localStore.setRangeType(rangeType);
	}, [localStore, rangeType]);

	useEffect(() => {
		localStore.setSelectedDate(selectedDate);
		localStore.setViewedDate(viewedDate);
	}, [localStore, selectedDate, viewedDate]);

	return {
		ref: spareRef,
		setViewedDate,
		setSelectedDate,
		getRange,
		getRangeType,
		getRangeWithMetadata,
		getViewedMonthName,
		getViewedYearNumber,
		getViewedDateLabelsBounds,
		slide,
		switchRangeTypeStepByStep,
		calculatePosition,
		calculatePositionDirection,
	};
}

export interface Options {
	ref?: React.RefObject<HTMLDivElement>;
	date?: Dayjs | string;
	format?: string;
	rangeType?: DatepickerRangeTypeKind;
	daysLimit?: number;
	yearsLimit?: number;
	maxDate?: Dayjs;
	minDate?: Dayjs;
}
