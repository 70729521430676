import { useCallback, useContext, useEffect } from "react";

import { storeContext } from "@core/store";
import { ModalStateType, ModalType } from "@core/types";

const modalTypeKeys = Object.keys(ModalType)
	.map(Number)
	.filter((key) => key >= 0) as ModalType[];

export function useModal() {
	const store = useContext(storeContext);

	const generateModalState = useCallback((callback?: (modal: ModalType) => ModalStateType) => {
		return modalTypeKeys.reduce(
			(acc, key) => ({
				...acc,
				[key]: callback ? callback(key as ModalType) : ModalStateType.Hidden,
			}),
			{} as Record<any, ModalStateType>
		);
	}, []);

	const forEachModalState = useCallback(
		(callback: (modal: ModalType, state: ModalStateType) => void) => {
			const modalState = store.layout.modalState;

			if (modalState) {
				modalTypeKeys.forEach((modal) => {
					callback(modal, modalState[modal]);
				});
			}
		},
		[store]
	);

	const getModalState = useCallback(
		(modal: ModalType) => {
			if (store.layout.modalState) {
				return store.layout.modalState[modal];
			}

			return null;
		},
		[store]
	);

	const findRelativeState = useCallback(
		(targetState: ModalStateType) => {
			let foundModal: ModalType | null = null;

			forEachModalState((modal, state) => {
				if (state === targetState) {
					foundModal = modal;
				}
			});

			return foundModal;
		},
		[forEachModalState]
	);

	const findVisible = useCallback(() => {
		return findRelativeState(ModalStateType.Visible);
	}, [findRelativeState]);

	const findShown = useCallback(() => {
		return findRelativeState(ModalStateType.Shown);
	}, [findRelativeState]);

	const findHiding = useCallback(() => {
		return findRelativeState(ModalStateType.Hiding);
	}, [findRelativeState]);

	const findNeedToShow = useCallback(() => {
		return findRelativeState(ModalStateType.NeedToShow);
	}, [findRelativeState]);

	const isVisible = useCallback(
		(modal: ModalType) => {
			return findVisible() === modal;
		},
		[findVisible]
	);

	const isShown = useCallback(
		(modal: ModalType) => {
			return findShown() === modal;
		},
		[findShown]
	);

	const isHiding = useCallback(
		(modal: ModalType) => {
			return findHiding() === modal;
		},
		[findHiding]
	);

	const isOpened = useCallback(
		(modal: ModalType) => {
			return isHiding(modal) === false && (isVisible(modal) || isShown(modal));
		},
		[isHiding, isShown, isVisible]
	);

	const show = useCallback(
		(targetModal: ModalType) => {
			const visibleModal = findVisible();
			const generatedModalState = generateModalState((modal) => {
				switch (true) {
					case visibleModal === modal:
						return ModalStateType.Hiding;
					case targetModal === modal:
						return visibleModal === null ? ModalStateType.Shown : ModalStateType.NeedToShow;
					default:
						return ModalStateType.Hidden;
				}
			});

			store.layout.setModalState(generatedModalState);
		},
		[store, findVisible, generateModalState]
	);

	const hide = useCallback(() => {
		const visibleModal = findVisible();
		const generatedModalState = generateModalState((modal) => {
			switch (true) {
				case visibleModal === modal:
					return ModalStateType.Hiding;
				default:
					return ModalStateType.Hidden;
			}
		});

		store.layout.setModalState(generatedModalState);
	}, [store, findVisible, generateModalState]);

	const handleHide = useCallback(() => {
		const generatedModalState = generateModalState((modal) => {
			switch (getModalState(modal)) {
				case ModalStateType.NeedToShow:
					return ModalStateType.Shown;
				default:
					return ModalStateType.Hidden;
			}
		});

		store.layout.setModalState(generatedModalState);
	}, [store, generateModalState, getModalState]);

	const handleShow = useCallback(() => {
		const generatedModalState = generateModalState((modal) => {
			switch (getModalState(modal)) {
				case ModalStateType.Shown:
					return ModalStateType.Visible;
				default:
					return ModalStateType.Hidden;
			}
		});

		store.layout.setModalState(generatedModalState);
	}, [store, generateModalState, getModalState]);

	useEffect(() => {
		if (store.layout.modalState === null) {
			store.layout.setModalState(generateModalState());
		}
	}, [store, generateModalState]);

	return {
		findRelativeState,
		findHiding,
		findShown,
		findVisible,
		findNeedToShow,
		isHiding,
		isShown,
		isVisible,
		isOpened,
		show,
		hide,
		handleShow,
		handleHide,
	};
}
