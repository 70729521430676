import React, { memo } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const Title: React.FC<Props> = memo(({ children, className, ...rest }) => {
	return (
		<h1 className={classNames(className, styles.title)} {...rest}>
			{children}
		</h1>
	);
});

export interface Props extends React.ComponentProps<"h1"> {}
