import React, { forwardRef, useMemo } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const FieldBase = forwardRef<HTMLLabelElement, Props>(
	({ children, label, className, labelConstantlyOnTop = false }, ref) => {
		const childrenValue = useMemo(() => {
			if (React.isValidElement(children)) {
				const { value } = children as any;

				return (value || "") as string;
			}

			return "";
		}, [children]);

		return (
			<label
				ref={ref}
				className={classNames(
					className,
					styles.fieldBase,
					(labelConstantlyOnTop || childrenValue.trim().length) && styles.constantlyOnTop
				)}>
				{children}
				<span className={styles.label} data-label>
					{label}
				</span>
			</label>
		);
	}
);

export interface Props {
	label: string;
	className?: string;
	children?: React.ReactNode;
	labelConstantlyOnTop?: boolean;
}
