import React, { memo } from "react";
import classNames from "classnames";

import { Button, Props as ButtonProps } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";

import styles from "./styles.module.scss";

export const LoadButton: React.FC<Props> = memo(
	({ children, className, loaded = false, disabled, ...rest }) => {
		return (
			<Button
				className={classNames(className, styles.loadButton, loaded && styles.loaded)}
				{...rest}
				disabled={disabled || loaded}>
				<span data-meta-content>{children}</span>
				{loaded ? <Spinner className={styles.spinner} /> : null}
			</Button>
		);
	}
);

export interface Props extends ButtonProps {
	loaded?: boolean;
}
