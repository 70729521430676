import React, { useEffect, useRef } from "react";
import Inputmask from "inputmask";

export function useInputMask(ref: React.RefObject<HTMLInputElement>, options: Options) {
	const maskInstanceRef = useRef<Inputmask.Instance>(null);

	useEffect(() => {
		// @ts-ignore
		maskInstanceRef.current = new Inputmask(options);

		return () => {
			if (maskInstanceRef.current) {
				maskInstanceRef.current.remove();
				// @ts-ignore
				maskInstanceRef.current = null;
			}
		};
	}, [options]);

	useEffect(() => {
		if (maskInstanceRef.current && ref.current) {
			maskInstanceRef.current.mask(ref.current);
		}
	}, [ref, maskInstanceRef]);
}

export interface Options extends Inputmask.Options {}
