import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { triggerEvent } from "@core/utils";

export function useSelect({
	ref,
	selectRef,
	optionDataAttributeName,
	openedClassName = "opened",
}: Options) {
	const spareRef = useRef<HTMLElement>(null);
	const spareSelectRef = useRef<HTMLSelectElement>(null);

	const targetRef = useMemo(() => ref || spareRef, [ref, spareRef]);
	const targetSelectRef = useMemo(() => selectRef || spareSelectRef, [selectRef, spareSelectRef]);

	const show = useCallback(() => {
		const targetElement = targetRef.current;

		if (targetElement) {
			targetElement.classList.add(openedClassName);
		}
	}, [targetRef, openedClassName]);

	const hide = useCallback(() => {
		const targetElement = targetRef.current;

		if (targetElement) {
			targetElement.classList.remove(openedClassName);
		}
	}, [targetRef, openedClassName]);

	const toggle = useCallback(() => {
		const targetElement = targetRef.current;

		if (targetElement) {
			targetElement.classList.toggle(openedClassName);
		}
	}, [targetRef, openedClassName]);

	const triggerChangeEvent = useCallback(
		(value: string) => {
			const selectElement = targetSelectRef.current;

			if (selectElement) {
				selectElement.value = value;
				triggerEvent(selectElement, "change");
			}
		},
		[targetSelectRef]
	);

	const handleOptionGroupClick = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			const targetParentElement = (event.target as HTMLElement).closest(
				`[${optionDataAttributeName}]`
			);

			if (targetParentElement) {
				const value = targetParentElement.getAttribute(optionDataAttributeName) || "";

				triggerChangeEvent(value);
				hide();
			}
		},
		[hide, optionDataAttributeName, triggerChangeEvent]
	);

	const handleDocumentClick = useCallback(
		(event: Event) => {
			const targetElement = targetRef.current;
			const selectElement = targetSelectRef.current;

			if (
				targetElement &&
				selectElement &&
				event.target !== selectElement &&
				targetElement.contains(event.target as any) === false
			) {
				hide();
			}
		},
		[hide, targetRef, targetSelectRef]
	);

	useEffect(() => {
		document.addEventListener("click", handleDocumentClick);

		return () => {
			document.removeEventListener("click", handleDocumentClick);
		};
	}, [handleDocumentClick]);

	return {
		ref: spareRef,
		selectRef: spareSelectRef,
		handleOptionGroupClick,
		triggerChangeEvent,
		toggle,
		show,
		hide,
	};
}

export interface Options {
	ref?: React.RefObject<HTMLElement>;
	selectRef?: React.RefObject<HTMLSelectElement>;
	openedClassName?: string;
	optionDataAttributeName: string;
}
