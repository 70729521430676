import React, { memo } from "react";
import classNames from "classnames";
import { Container as ContainerImpl } from "react-bootstrap";

import styles from "./styles.module.scss";

export const Container: React.FC<Props> = memo(({ children, className, ...rest }) => {
	return (
		<ContainerImpl className={classNames(styles.container, className)} {...(rest as any)}>
			{children}
		</ContainerImpl>
	);
});

export interface Props extends React.ComponentProps<"div"> {}
