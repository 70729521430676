import React, { forwardRef } from "react";

import { FieldBase, Props as FieldBaseProps } from "@components/ui/FieldBase";

export const Input = forwardRef<HTMLInputElement, Props>(
	({ label, className, placeholder, ...rest }, ref) => {
		return (
			<FieldBase className={className} label={label}>
				<input {...rest} ref={ref} className='field' />
			</FieldBase>
		);
	}
);

export interface Props extends React.ComponentProps<"input">, FieldBaseProps {}
