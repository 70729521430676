import { useCallback, useContext } from "react";

import { storeContext } from "@core/store";

export function useScrollLocker(key: string) {
	const store = useContext(storeContext);

	const lock = useCallback(() => {
		const subscribers = store.layout.scrollLockSubscribers;

		if (!subscribers.includes(key)) {
			store.layout.setScrollLockSubscribers([...subscribers, key]);
		}

		if (store.layout.scrollLockSubscribers.length) {
			document.body.style.overflow = "hidden";
			document.documentElement.style.overflow = "hidden";
		}
	}, [store, key]);

	const unlock = useCallback(() => {
		const subscribers = store.layout.scrollLockSubscribers;

		if (subscribers.includes(key)) {
			store.layout.setScrollLockSubscribers(subscribers.filter((suspectKey) => suspectKey !== key));
		}

		if (!store.layout.scrollLockSubscribers.length) {
			document.body.style.overflowY = "auto";
			document.documentElement.style.overflowY = "auto";
		}
	}, [store, key]);

	return { lock, unlock };
}
