import React, { useCallback, useEffect } from "react";
import { Observer } from "mobx-react-lite";
import classNames from "classnames";

import { Tween } from "@components/hoc/Tween";
import { VisibleSwitcher } from "@components/hoc/VisibleSwitcher";

import { Section } from "@components/simple/Section";

import { Container } from "@components/ui/Container";
import { ContainerWrapper } from "@components/ui/ContainerWrapper";
import { CardContent } from "@components/ui/CardContent";
import { Title } from "@components/ui/Title";

import { useLocalStoreFlat } from "@core/hooks";

import { getIconImage } from "@assets";

import { questions } from "./data";
import styles from "./styles.module.scss";

export const QuestionsSection: React.FC = () => {
	const localStore = useLocalStoreFlat({ openedQuestions: [] as number[] });

	const createQuestionClickHandler = useCallback(
		(id: number) => (event: React.MouseEvent) => {
			event.preventDefault();

			const openedQuestions = localStore.openedQuestions;

			if (openedQuestions.includes(id)) {
				localStore.setOpenedQuestions(openedQuestions.filter((questionId) => questionId !== id));
			} else {
				localStore.setOpenedQuestions([...openedQuestions, id]);
			}
		},
		[localStore]
	);

	const handleBodyClick = useCallback((event: React.MouseEvent) => {
		event.stopPropagation();
	}, []);

	useEffect(() => {
		localStore.setOpenedQuestions([]);
	}, [localStore]);

	return (
		<Section className={styles.section}>
			<CardContent className={styles.content}>
				<Container>
					<Title>Вопросы и ответы</Title>
				</Container>
				<div className={styles.questions}>
					<Observer>
						{() => (
							<>
								{questions.map((question, index) => (
									<ContainerWrapper
										key={`question-${index}`}
										className={classNames(styles.question, "card")}
										onClick={createQuestionClickHandler(index)}>
										<Container>
											<div className={styles.head}>
												<p className={styles.title}>{question.question}</p>
												<Tween
													to={{ rotate: localStore.openedQuestions.includes(index) ? 180 : 0 }}
													duration={0.4}
													force3D={true}
													easing='circ.out'>
													<div className={styles.icon}>{getIconImage("chevronDown")}</div>
												</Tween>
											</div>
											<VisibleSwitcher
												isVisible={localStore.openedQuestions.includes(index)}
												duration={0.4}
												easing='circ.out'>
												<div className={styles.body} onClick={handleBodyClick}>
													<p className={styles.answer}>{question.answer}</p>
												</div>
											</VisibleSwitcher>
										</Container>
									</ContainerWrapper>
								))}
							</>
						)}
					</Observer>
				</div>
			</CardContent>
		</Section>
	);
};
