import React, { useMemo, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { Observer } from "mobx-react-lite";
import classNames from "classnames";

import { ErrorField } from "@components/smart/ErrorField";

import { PromptWrapper } from "@components/ordinary/PromptWrapper";

import { Section } from "@components/simple/Section";

import { ContainerWrapper } from "@components/ui/ContainerWrapper";
import { LoadButton } from "@components/ui/LoadButton";
import { Container } from "@components/ui/Container";
import { Image } from "@components/ui/Image";
import { Title } from "@components/ui/Title";

import { useAPI, useForm, useLocalStoreFlat, useModal, useQueryParam } from "@core/hooks";
import { calculateServices } from "@core/services";
import { contentConfig } from "@core/config";
import { dateHelpers } from "@core/helpers";
import { formatPhoneNumber, normalizePhoneNumber, calculatePriceWithDiscount } from "@core/utils";
import { ModalType } from "@core/types";

import { getIconImage } from "@assets";

import { formSchema } from "./schemas";
import styles from "./styles.module.scss";

import AlfaLogoWhiteSource from "@assets/images/alfa-logo-white.svg";
import BeregLogoWhiteSource from "@assets/images/bereg-logo-white.svg";
import { NeedManagerHelp } from "@modals/NeedManagerHelp";

export const PromoSection: React.FC<Props> = ({ onIssueButtonClick, onLogoClick }) => {
	const modal = useModal();
	const withoutPhone = useQueryParam("utm_source") === "yandex";
	const localStore = useLocalStoreFlat({ total: null as number | null });
	const calculateForm = useForm(formSchema, {
		initialValues: {
			isRealty: "true",
			isLifeAndHealth: "true",
			firstname: withoutPhone ? "Неизвестно" : undefined,
			phone: withoutPhone ? "+79000000000" : undefined,
		},
		onSomeFieldChange: () => {
			if (localStore.total) {
				localStore.setTotal(0);
			}
		},
	});

	const formattedPhoneNumber = useMemo(() => {
		return `+7 ${formatPhoneNumber(
			contentConfig.phoneNumber,
			contentConfig.phoneNumberFormatsPieces.default
		)}`;
	}, []);

	const silentSaveDataRequest = useAPI(calculateServices.saveManager);
	const saveDataRequest = useAPI(calculateServices.saveManager);
	const calculateRequest = useAPI(calculateServices.calculate);

	const sendData = useCallback(
		(priority?: number) => {
			return (priority === 0 ? silentSaveDataRequest : saveDataRequest).call({
				sum: Number(calculateForm.getValue("total")),
				insurance_life: calculateForm.getValue("isLifeAndHealth") ? 1 : 0,
				insurance_property: calculateForm.getValue("isRealty") ? 1 : 0,
				policyholder_date_birth: calculateForm.getValue("birthDate"),
				policyholder_gender: calculateForm.getValue("gender") === "1" ? "male" : "female",
				policyholder_name: calculateForm.getValue("firstname"),
				policyholder_phone: `+7${normalizePhoneNumber(calculateForm.getValue("phone"))}`,
				priority: priority,
			});
		},
		[silentSaveDataRequest, saveDataRequest, calculateForm]
	);

	const handleManagerButtonClick = useCallback(() => {
		sendData(1)
			.then(() => {
				modal.show(ModalType.OrderBeingProcessed);
			})
			.catch(console.error);
	}, [sendData, modal]);

	const handleFormSubmit = useCallback(
		(event: React.FormEvent) => {
			event.preventDefault();

			const currentDate = dateHelpers.getCurrentDate();

			const parsedBirthDate = dateHelpers.parse(calculateForm.getValue("birthDate"), "DD.MM.YYYY");
			const years = currentDate.diff(parsedBirthDate, "year");
			const isUnder18 = years < 18;
			const isUnderNow = currentDate.isAfter(parsedBirthDate);
			const isCorrectDate = parsedBirthDate.isValid();

			if (calculateForm.validate() && !isUnder18 && isCorrectDate && isUnderNow) {
				calculateForm.setDisabled(true);

				sendData(0).catch(console.error);

				calculateRequest
					.call({
						fio: calculateForm.getValue("firstname"),
						phone: normalizePhoneNumber(calculateForm.getValue("phone")),
						gender: calculateForm.getValue("gender"),
						creditRemain: calculateForm.getValue("total").replace(" ", ""),
						bdate: calculateForm.getValue("birthDate"),
					})
					.then((response) => {
						const estate = calculateForm.getValue("isRealty") === "true" ? response.estate : 0;
						const life = calculateForm.getValue("isLifeAndHealth") === "true" ? response.life : 0;

						const total = Math.ceil(estate + life);

						if (withoutPhone) {
							calculateForm.setValue("phone", "");
						}

						localStore.setTotal(total);
					})
					.catch(console.error)
					.finally(() => {
						calculateForm.setDisabled(false);
					});
			} else if (isCorrectDate && !isUnderNow) {
				calculateForm.setError("birthDate", "Вы из будущего? :)");
			} else if (isCorrectDate && isUnder18) {
				calculateForm.setError("birthDate", "Ваш возраст должен быть не менее 18 лет");
			}
		},
		[calculateForm, sendData, calculateRequest, withoutPhone, localStore]
	);

	const handleShowNeedManagerHelpModalButtonClick = useCallback(() => {
		modal.show(ModalType.NeedManagerHelp);
	}, [modal]);

	return (
		<Section className={styles.section}>
			<ContainerWrapper className={styles.wrapper}>
				<Container className={styles.container}>
					<Row>
						<Col xs={12} md={12} lg={5}>
							<div className={styles.info}>
								<div className={styles.logoGroup}>
									<button type='button'>
										<Image className={styles.logo} src={AlfaLogoWhiteSource} />
									</button>
									<div className={styles.separator}></div>
									<button type='button' onClick={onLogoClick}>
										<Image className={styles.logo} src={BeregLogoWhiteSource} />
									</button>
								</div>
								<div className={styles.description}>
									<p>
										<span>
											Скидка до 30%
											<br />
											на ипотечное
											<br />
											страхование
										</span>

										<p className={styles.note}>
											при оформлении
											<br />с менеджером
											<br />
											онлайн
										</p>
									</p>
									<p className={styles.mobile}>
										<span>
											Скидка до 30%
											<br />
											на ипотечное страхование
										</span>
										<p className={styles.note}>
											при оформлении
											<br />с менеджером онлайн
										</p>
									</p>
								</div>
								<div className={styles.contacts}>
									<a href={`tel:${contentConfig.phoneNumber}`}>
										<p className={styles.phoneNumber}>{formattedPhoneNumber}</p>
									</a>
									<div className={styles.messengers}>
										<a href={contentConfig.telegramLink} target='_blank' rel='noopener noreferrer'>
											<div className={styles.icon}>{getIconImage("telegram")}</div>
										</a>
										<a href={contentConfig.whatsAppLink} target='_blank' rel='noopener noreferrer'>
											<div className={styles.icon}>{getIconImage("whatsApp")}</div>
										</a>
									</div>
								</div>
							</div>
						</Col>
						<Col className={styles.formColumn} xs={12} md={12} lg={7}>
							<form className={classNames("card", styles.form)} onSubmit={handleFormSubmit}>
								<Title className={styles.title}>Убедитесь в выгоде</Title>
								<div className={styles.formContent}>
									<Row>
										<Col xs={12} md={12} lg={6}>
											<Row>
												<Col xs={12} md={12} className={styles.fieldGroup}>
													<div className={styles.field}>
														<PromptWrapper
															type='input'
															text='При оформлении нового ипотечного кредита - введите сумму кредита. Если у вас дейтсвующий кредит - введите остаток долга в рублях на момент окончания вашего текущего полиса страхования.'>
															<Observer>{() => <ErrorField {...calculateForm.bind("total")} />}</Observer>
														</PromptWrapper>
													</div>
												</Col>
												<Col xs={12} md={12} className={styles.fieldGroup}>
													<div className={styles.field}>
														<PromptWrapper
															type='checkbox'
															text='Данный вид страхования используется в том случае, когда ваша купленная недвижимость уже находится в вашей собственности.'>
															<Observer>{() => <ErrorField {...calculateForm.bind("isRealty")} />}</Observer>
														</PromptWrapper>
													</div>
												</Col>
												<Col xs={12} md={12} className={styles.fieldGroup}>
													<PromptWrapper
														type='checkbox'
														text='Страхование рисков кредитной организации на случай утери кредитором возможности выполнения кредитных обязательств'>
														<Observer>{() => <ErrorField {...calculateForm.bind("isLifeAndHealth")} />}</Observer>
													</PromptWrapper>
												</Col>
											</Row>
										</Col>
										<Col xs={12} md={12} lg={6}>
											{!withoutPhone ? (
												<Col xs={12} md={12} className={styles.fieldGroup}>
													<div className={styles.field}>
														<Observer>{() => <ErrorField {...calculateForm.bind("firstname")} />}</Observer>
													</div>
												</Col>
											) : null}
											{!withoutPhone ? (
												<Col xs={12} md={12} className={styles.fieldGroup}>
													<div className={styles.field}>
														<Observer>{() => <ErrorField {...calculateForm.bind("phone")} />}</Observer>
													</div>
												</Col>
											) : null}
											<Col xs={12} md={12} className={styles.fieldGroup}>
												<div className={styles.field}>
													<Observer>{() => <ErrorField {...calculateForm.bind("birthDate")} />}</Observer>
												</div>
											</Col>
											<Col xs={12} md={12} className={styles.fieldGroup}>
												<div className={styles.field}>
													<Observer>{() => <ErrorField {...calculateForm.bind("gender")} />}</Observer>
												</div>
											</Col>
										</Col>
									</Row>
									{/* <Row className={styles.fieldGroup}>
										<Col xs={12} md={6}>
											<div className={styles.field}>
												<PromptWrapper
													type='input'
													text='При оформлении нового ипотечного кредита - введите сумму кредита. Если у вас дейтсвующий кредит - введите остаток долга в рублях на момент окончания вашего текущего полиса страхования.'>
													<Observer>{() => <ErrorField {...calculateForm.bind("total")} />}</Observer>
												</PromptWrapper>
											</div>
										</Col>
										<Col xs={12} md={6}>
											<div className={styles.field}>
												<Observer>{() => <ErrorField {...calculateForm.bind("firstname")} />}</Observer>
											</div>
										</Col>
									</Row>
									<Row className={styles.fieldGroup}>
										<Col xs={12} md={6}>
											<PromptWrapper
												type='checkbox'
												text='Данный вид страхования используется в том случае, когда ваша купленная недвижимость уже находится в вашей собственности.'>
												<Observer>{() => <ErrorField {...calculateForm.bind("isRealty")} />}</Observer>
											</PromptWrapper>
										</Col>
										<Col xs={12} md={6}>
											<div className={styles.field}>
												<Observer>{() => <ErrorField {...calculateForm.bind("phone")} />}</Observer>
											</div>
										</Col>
									</Row>
									<Row className={styles.fieldGroup}>
										<Col xs={12} md={6}>
											<PromptWrapper
												type='checkbox'
												text='Страхование рисков кредитной организации на случай утери кредитором возможности выполнения кредитных обязательств'>
												<Observer>{() => <ErrorField {...calculateForm.bind("isLifeAndHealth")} />}</Observer>
											</PromptWrapper>
										</Col>
										<Col xs={12} md={6}>
											<div className={styles.field}>
												<Observer>{() => <ErrorField {...calculateForm.bind("birthDate")} />}</Observer>
											</div>
										</Col>
									</Row>
									<Row className={styles.fieldGroup}>
										<Col xs={12} md={6}></Col>
										<Col xs={12} md={6}>
											<div className={styles.field}>
												<Observer>{() => <ErrorField {...calculateForm.bind("gender")} />}</Observer>
											</div>
										</Col>
									</Row> */}
								</div>
								<div className={styles.controls}>
									<Observer>
										{() =>
											localStore.total ? (
												<div className={styles.buttonGroup}>
													<div className={styles.priceGroup}>
														<p className={classNames(styles.price, styles.old)}>
															{Math.ceil(localStore.total || 0).toLocaleString()} руб.
														</p>
														<p className={classNames(styles.price, styles.current)}>
															{Number(
																calculatePriceWithDiscount(localStore.total || 0, 20).toFixed(0)
															).toLocaleString()}{" "}
															руб.
														</p>
													</div>
													<LoadButton
														theme='primary-red'
														className={classNames(styles.button, styles.manager)}
														onClick={
															withoutPhone ? handleShowNeedManagerHelpModalButtonClick : handleManagerButtonClick
														}
														loaded={saveDataRequest.isPending()}
														iconLeft='gift'>
														Получить скидку
													</LoadButton>
												</div>
											) : (
												<LoadButton
													type='submit'
													theme='primary-red'
													loaded={calculateRequest.isPending()}
													disabled={
														!calculateForm.getValue("isRealty") && !calculateForm.getValue("isLifeAndHealth")
													}
													className={classNames(styles.button, styles.main)}>
													Узнать цену
												</LoadButton>
											)
										}
									</Observer>
								</div>
							</form>
							<div className={classNames(styles.contacts, styles.mobile)}>
								<a href={`tel:${contentConfig.phoneNumber}`}>
									<p className={styles.phoneNumber}>{formattedPhoneNumber}</p>
								</a>
								<div className={styles.messengers}>
									<a href={contentConfig.telegramLink} target='_blank' rel='noopener noreferrer'>
										<div className={styles.icon}>{getIconImage("telegram")}</div>
									</a>
									<a href={contentConfig.whatsAppLink} target='_blank' rel='noopener noreferrer'>
										<div className={styles.icon}>{getIconImage("whatsApp")}</div>
									</a>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</ContainerWrapper>
			<Observer>
				{() =>
					localStore.total ? (
						<NeedManagerHelp
							formData={{
								sum: Number(calculateForm.getValue("total")),
								insurance_life: calculateForm.getValue("isLifeAndHealth") ? 1 : 0,
								insurance_property: calculateForm.getValue("isRealty") ? 1 : 0,
								policyholder_date_birth: calculateForm.getValue("birthDate"),
								policyholder_gender: calculateForm.getValue("gender") === "1" ? "male" : "female",
							}}
						/>
					) : null
				}
			</Observer>
		</Section>
	);
};

export interface Props {
	onIssueButtonClick?: () => void;
	onLogoClick?: () => void;
}
