import React, { forwardRef } from "react";
import useMeasure from "react-use-measure";
import { Observer } from "mobx-react-lite";
import classNames from "classnames";

import { BrandingBanner } from "@banners/BrandingBanner";
import { BonusBanner } from "@banners/BonusBanner";
import { DogBanner } from "@banners/DogBanner";

import { Section } from "@components/simple/Section";

import { Title } from "@components/ui/Title";
import { Container } from "@components/ui/Container";
import { CardContent } from "@components/ui/CardContent";
import { ContainerWrapper } from "@components/ui/ContainerWrapper";

import { useSlider } from "@core/hooks";

import { getIconImage } from "@assets";

import styles from "./styles.module.scss";

const SLIDES = [BrandingBanner, BonusBanner, DogBanner];

export const CarouselSection = forwardRef<HTMLDivElement>((_, ref) => {
	const slider = useSlider(SLIDES.length);
	const [sliderWrapperRef, sliderWrapperBounds] = useMeasure();

	return (
		<Section ref={ref} className={styles.section}>
			<ContainerWrapper className={classNames(styles.wrapper, "card")}>
				<CardContent className={styles.content}>
					<Container>
						<Title className={styles.title}>Что такое Берег?</Title>
					</Container>
					<ContainerWrapper className={styles.carousel}>
						<div ref={sliderWrapperRef} className={styles.sliderWrapper}>
							<Observer>
								{() => (
									<div
										className={styles.sliderGroup}
										style={{
											["--container-width" as any]: `${sliderWrapperBounds.width}px`,
											["--slide-index" as any]: slider.getCurrentIndex(),
										}}>
										<div className={styles.slider}>
											{SLIDES.map((Component, index) => (
												<div key={index} className={styles.slideGroup}>
													<Component className={styles.slide} />
												</div>
											))}
											<a href='https://ibereg.ru' target='_blank'>
												<div className={classNames(styles.slideGroup, styles.extra)}>
													<div className={styles.slide}>
														<div className={styles.content}>Подробнее</div>
													</div>
												</div>
											</a>
										</div>
									</div>
								)}
							</Observer>
						</div>
						<div className={classNames("banner", styles.controllers)}>
							<div className={styles.buttonGroup}>
								<button
									type='button'
									className={classNames(styles.button, styles.prev)}
									onClick={slider.prev}>
									<div className={styles.icon}>{getIconImage("chevronDown")}</div>
								</button>
								<button
									type='button'
									className={classNames(styles.button, styles.next)}
									onClick={slider.next}>
									<div className={styles.icon}>{getIconImage("chevronDown")}</div>
								</button>
							</div>
						</div>
					</ContainerWrapper>
				</CardContent>
			</ContainerWrapper>
		</Section>
	);
});
