import React from "react";

import { IconNameKind } from "@assets";

export const steps: { description: React.ReactNode; iconName: IconNameKind }[] = [
	{
		iconName: "calculate",
		description: (
			<>
				Рассчитайте стоимость
				<br />
				полиса страхования
			</>
		),
	},
	{
		iconName: "postAdd",
		description: (
			<>
				Внесите данные для
				<br />
				оформления договора
			</>
		),
	},
	{
		iconName: "creditCard",
		description: (
			<>
				Оплатите на сайте
				<br />
				страховой
			</>
		),
	},
	{
		iconName: "attachEmail",
		description: (
			<>
				Получите готовый полис
				<br />
				по электропочте
			</>
		),
	},
];

export const reasons: { title: React.ReactNode; description: React.ReactNode }[] = [
	{
		title: "Выгодно",
		description: <>Скидка на полис до 30%</>,
	},
	{
		title: "Быстро",
		description: (
			<>
				Не нужно ехать в офис —<br />
				все происходит онлайн
			</>
		),
	},
	{
		title: "Легко",
		description: (
			<>
				Не нужно искать
				<br />и предоставлять документы
			</>
		),
	},
];
