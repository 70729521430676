import React, { useCallback, useRef } from "react";

import { Layout } from "@containers/Layout";
import { PromoSection } from "@containers/PromoSection";
import { StepsSection } from "@containers/StepsSection";
import { CalculateSection } from "@containers/CalculateSection";
import { QuestionsSection } from "@containers/QuestionsSection";
import { CarouselSection } from "@containers/CarouselSection";

import { tweenHelpers } from "@core/helpers";

export const Main: React.FC = () => {
	const calculateRef = useRef<HTMLDivElement>(null);
	const carouselRef = useRef<HTMLDivElement>(null);

	const handleIssueButtonClick = useCallback(() => {
		tweenHelpers.scrollToTarget(calculateRef.current, { duration: 1, offset: -20 });
	}, []);

	const handleLogoClick = useCallback(() => {
		tweenHelpers.scrollToTarget(carouselRef.current, { duration: 1, offset: -20 });
	}, []);

	return (
		<Layout>
			<PromoSection onIssueButtonClick={handleIssueButtonClick} onLogoClick={handleLogoClick} />
			{/* <CalculateSection ref={calculateRef} /> */}
			<StepsSection />
			<CarouselSection ref={carouselRef} />
			<QuestionsSection />
		</Layout>
	);
};
