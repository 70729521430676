import React, { forwardRef, useMemo } from "react";
import classNames from "classnames";

import { FieldBase, Props as FieldBaseProps } from "@components/ui/FieldBase";

import { useSelect } from "@core/hooks";
import { mergeRefs } from "@core/utils";

import { getIconImage } from "@assets";

import styles from "./styles.module.scss";

const OPTION_VALUE_ATTRIBUTE_NAME = "data-option-value";

export const Select = forwardRef<HTMLSelectElement, Props>(
	({ value, label, defaultOption, options = [], disabled, className, ...rest }, ref) => {
		const select = useSelect({
			optionDataAttributeName: OPTION_VALUE_ATTRIBUTE_NAME,
			openedClassName: "opened",
		});
		const mergedSelectRef = useMemo(() => mergeRefs(ref || select.selectRef), [ref, select]);
		const mergedOptions = useMemo(() => [defaultOption, ...options], [defaultOption, options]);

		const selectedOption = useMemo(
			() => mergedOptions.find((optionData) => optionData.value === value),
			[mergedOptions, value]
		);

		const selectedOptionData = useMemo(() => {
			if (selectedOption) {
				return selectedOption;
			}

			return {
				label: defaultOption.label,
				value: defaultOption.value,
			};
		}, [selectedOption, defaultOption]);

		const memoizedCustomOptions = useMemo(() => {
			return mergedOptions.map((optionData, index) => {
				const isSelected = optionData.value === selectedOptionData.value;

				return (
					<div
						{...{ [OPTION_VALUE_ATTRIBUTE_NAME]: optionData.value }}
						key={`custom-option-${optionData.value}-${index}`}
						className={classNames(styles.option, isSelected && styles.selected)}>
						<p className={styles.label}>{optionData.label}</p>
						{/* <div className={styles.iconGroup}>{getIconImage("checkInCircle")}</div> */}
					</div>
				);
			});
		}, [mergedOptions, selectedOptionData]);

		const memoizedOptions = useMemo(() => {
			return mergedOptions.map((optionData, index) => (
				<option key={`option-${optionData.value}-${index}`} value={optionData.value}>
					{optionData.label}
				</option>
			));
		}, [mergedOptions]);

		return (
			<FieldBase
				label={label}
				className={classNames(styles.selectGroup, className)}
				labelConstantlyOnTop={true}>
				<select
					ref={mergedSelectRef}
					className={classNames(styles.nativeSelect, "field")}
					disabled={disabled}
					value={selectedOptionData.value}
					{...rest}>
					{memoizedOptions}
				</select>
				<div ref={select.ref as any} className={classNames(styles.select, "noselect")}>
					<div
						className={classNames(styles.head, "field")}
						onClick={!disabled ? select.toggle : undefined}
						data-head>
						<span className={styles.label}>{selectedOptionData.label}</span>
						<span className={styles.iconGroup} data-icon>
							{getIconImage("chevronDown")}
						</span>
					</div>
					<div
						className={classNames(styles.optionGroup, "field")}
						onClick={select.handleOptionGroupClick}>
						{memoizedCustomOptions}
					</div>
				</div>
			</FieldBase>
		);
	}
);

export interface Props extends React.ComponentProps<"select">, Pick<FieldBaseProps, "label"> {
	options?: Option[];
	defaultOption: Option;
}

type Option = { label: string; value: string };
