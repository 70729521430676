import { CSSStyleKeyKind } from "@core/types";

export const tweenDataAttributeName = "data-tween-item";
export const tweenPropsToCssPropsAssociation: {
	tweenProps: (keyof gsap.CSSVars)[];
	cssProps: CSSStyleKeyKind[];
}[] = [
	{
		tweenProps: ["x", "y", "z", "rotateX", "rotateY", "rotateZ"],
		cssProps: ["transform"],
	},
	{ tweenProps: ["autoAlpha"], cssProps: ["opacity"] },
	{ tweenProps: ["left", ""], cssProps: ["left"] },
	{ tweenProps: ["right"], cssProps: ["right"] },
	{ tweenProps: ["bottom"], cssProps: ["bottom"] },
	{ tweenProps: ["top"], cssProps: ["top"] },
];
