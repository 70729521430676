import dayjs, { Dayjs, UnitType } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { createArray } from "@core/utils";

dayjs.extend(customParseFormat);

export const monthNameList = [
	"Январь",
	"Февраль",
	"Март",
	"Апрель",
	"Май",
	"Июнь",
	"Июль",
	"Август",
	"Сентябрь",
	"Октябрь",
	"Ноябрь",
	"Декабрь",
];

export const monthGenitiveNameList = [
	"января",
	"февраля",
	"марта",
	"апреля",
	"мая",
	"июня",
	"июля",
	"августа",
	"сентября",
	"октября",
	"ноября",
	"декабря",
];

export const shortedMonthNameList = [
	"Янв",
	"Фев",
	"Мар",
	"Апр",
	"Май",
	"Июн",
	"Июл",
	"Авг",
	"Сен",
	"Окт",
	"Ноя",
	"Дек",
];

export const weekdayNameList = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

/**
 * Парсит строку в дату
 */
export function parse(date: string, format?: string) {
	return dayjs(date, format);
}

/**
 * Опционально парсит строку в дату
 */
export function conditionalParse(date?: string | Dayjs, format?: string) {
	switch (true) {
		case typeof date === "string" && typeof format === "string":
			return parse(date as string, format as string);
		case typeof date === "object":
			return date as Dayjs;
		default:
			return null;
	}
}

/**
 * Возвращает список месяцев в году
 */
export function getMonthsRange(date: Dayjs) {
	return createArray(12).map((_, index) => date.clone().month(index));
}

/**
 * Возвращает список дней в месяце
 */
export function getDaysRange(date: Dayjs) {
	return createArray(date.daysInMonth()).map((_, index) => date.clone().date(index + 1));
}

/**
 * Возвращает день недели в ISO формате
 */
export function getISOWeekday(date: Dayjs) {
	const weekdayNumber = date.get("day");
	const shiftedWeekdayNumber = weekdayNumber - 1;

	return shiftedWeekdayNumber < 0 ? 6 : shiftedWeekdayNumber;
}

/**
 * Возвращает текущую дату
 */
export function getCurrentDate() {
	return dayjs();
}

/**
 * Добавляет метадату в массив дат
 */
function addMetadata(
	range: (Dayjs | DateWithMetadata)[],
	{ insideInRange = true, unitType }: { insideInRange?: boolean; unitType: UnitType }
) {
	const currentDate = getCurrentDate();

	return range.map((date) => {
		if ((date as Dayjs).isValid) {
			return {
				date: date as Dayjs,
				insideInRange: insideInRange,
				isCurrent: currentDate.isSame(date as Dayjs, unitType),
				label: (date as Dayjs).get(unitType),
			} as DateWithMetadata;
		}

		return date as DateWithMetadata;
	});
}

type CaseType = "genitive" | "nominative";

/**
 * Возвращает полное название месяца. Например: Янв, Фев, Мар и т.д.
 */
export function getMonthName(date: Dayjs, caseType: CaseType = "nominative") {
	let nameList: string[];

	switch (caseType) {
		case "genitive":
			nameList = monthGenitiveNameList;
			break;
		default:
			nameList = monthNameList;
	}

	return nameList[date.get("month")];
}

/**
 * Возвращает сокращенное название месяца. Например: Янв, Фев, Мар и т.д.
 */
export function getShortedMonthName(date: Dayjs) {
	return shortedMonthNameList[date.get("month")];
}

/**
 * Возвращает список дней с заполненными границами справа и слева
 */
export function getDaysRangeWithBounds(date: Dayjs, limit: number) {
	let daysRange = getDaysRange(date);

	let leftBound = [] as DateWithMetadata[];
	let rightBound = [] as DateWithMetadata[];

	const firstWeekdayNumber = getISOWeekday(daysRange[0]);

	if (firstWeekdayNumber !== 0) {
		const shiftedMonth = date.clone().subtract(1, "month");
		const daysCountInShiftedMonth = shiftedMonth.daysInMonth();
		const clearLeftBound = createArray(firstWeekdayNumber)
			.reduce((acc: Dayjs[], _, index) => {
				return [...acc, shiftedMonth.date(daysCountInShiftedMonth - index)];
			}, [])
			.reverse();

		leftBound = addMetadata(clearLeftBound, { insideInRange: false, unitType: "date" });
	}

	{
		const differenceBetweenDaysRangeLengthAndLimit = limit - (daysRange.length + leftBound.length);
		const shiftedMonth = date.clone().add(1, "month");
		const clearRightBound = createArray(differenceBetweenDaysRangeLengthAndLimit).reduce(
			(acc: Dayjs[], _, index) => {
				return [...acc, shiftedMonth.date(index + 1)];
			},
			[]
		);

		rightBound = addMetadata(clearRightBound, {
			insideInRange: false,
			unitType: "date",
		});
	}

	return addMetadata([...leftBound, ...daysRange, ...rightBound], {
		unitType: "date",
	});
}

/**
 * Возвращает список годов с заполненными границами справа и слева
 */
export function getYearsRangeWithBounds(date: Dayjs, limit: number) {
	const yearNumber = date.get("year");
	const lastYearNumber = yearNumber % limit;
	const firstYearNumber = yearNumber - lastYearNumber;
	const yearsRange = createArray(limit).map((_, index) =>
		date.clone().year(firstYearNumber + index)
	);
	const leftBound = yearsRange[0].clone().subtract(1, "year");
	const rightBound = yearsRange[yearsRange.length - 1].clone().add(1, "year");

	return [
		...addMetadata([leftBound], { insideInRange: false, unitType: "year" }),
		...addMetadata(yearsRange, { unitType: "year" }),
		...addMetadata([rightBound], { insideInRange: false, unitType: "year" }),
	];
}

/**
 * Возвращает список месяцев с заполненными границами справа и слева
 */
export function getMonthsRangeWithBounds(date: Dayjs) {
	return addMetadata(getMonthsRange(date), {
		insideInRange: true,
		unitType: "month",
	}).map((date) => ({ ...date, label: getMonthName(date.date) }));
}

/**
 * Возрващает текущее время в миллисекундах
 */

export function getFastestCurrentDateInMilliseconds() {
	return Date.now();
}

/**
 *
 * @param dateA - Дата, из которой вычисляется разница
 * @param dateB - Дата, которая вычисляется из dateA
 * @param unit - Единица времени, которая возвращается
 */

export function getDifference(dateA: Dayjs | null, dateB: Dayjs | null, unit?: UnitType) {
	return dateA && dateB ? dateA.clone().diff(dateB.clone(), unit) : 0;
}

/**
 * Возвращает разницу между датами в виде даты
 * @param dateA - Дата, из которой вычисляется разница
 * @param dateB - Дата, которая вычисляется из dateA
 * @param unit - Единица времени, которая возвращается
 */

export function getDifferenceAsDate(...args: Parameters<typeof getDifference>) {
	return dayjs(getDifference(...args));
}

export function convertDifferenceDaysToHours(dateA: Dayjs | null, dateB: Dayjs | null) {
	const differenceAsDate = getDifferenceAsDate(dateA, dateB);

	return differenceAsDate.get("days") * 24 + differenceAsDate.get("hour");
}

export interface DateWithMetadata {
	date: Dayjs;
	label: string | number;
	insideInRange: boolean;
	isCurrent: boolean;
}

export { Dayjs };
