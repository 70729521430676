import { api } from "@core/api";
import { routesConfig } from "@core/config";

import { API } from "@core/types";
import { CalculateModels } from "@core/models";

export function calculate(params: {
	fio: string;
	phone: string;
	bdate: string;
	creditRemain: number | string;
	gender: number | string;
}) {
	return api.post<API.Response<CalculateModels.Data>>(routesConfig.apiRoutes.calc, params);
}

export function saveManager(params: {
	sum: number;
	insurance_life: number;
	insurance_property: number;
	policyholder_name: string;
	policyholder_date_birth: string;
	policyholder_gender: "male" | "female";
	policyholder_phone: string;
	priority?: number;
}) {
	return api.post(routesConfig.apiRoutes.saveManager, params);
}

export function save(params: {
	region: string;
	sum: number;
	contract_number: string;
	contract_date: string;
	insurance_start_date: string;
	insurance_life: string | boolean | number;
	insurance_life_profession?: string | boolean | number;
	insurance_life_profession_text?: string;
	insurance_life_sport?: string | boolean | number;
	insurance_life_sport_text?: string;
	insurance_life_disease?: string | boolean | number;
	insurance_life_disease_text?: string;
	insurance_property: string | boolean | number;
	insurance_property_address?: string;
	insurance_property_type?: string;
	insurance_property_area?: number;
	insurance_property_price?: number;
	insurance_property_year?: number;
	insurance_property_wood?: number;
	policyholder_name: string;
	policyholder_date_birth: string;
	policyholder_gender: "male" | "female";
	policyholder_phone: string;
	policyholder_passport_number: string;
	policyholder_passport_type: "0" | "1";
	policyholder_passport_date: string;
	policyholder_passport_organization: string;
	policyholder_passport_address: string;
}) {
	return api.post(routesConfig.apiRoutes.save, {
		bank: "Сбербанк",
		phone_confirm: true,
		accept_insurance: true,
		...params,
	});
}
