import React, { useMemo } from "react";

import { Tween, Props as TweenProps } from "@components/hoc/Tween";

export const VisibleSwitcher: React.FC<Props> = ({ children, isVisible = true, ...rest }) => {
	const tweenProps = useMemo(
		() => (isVisible ? { autoAlpha: 1, display: "block" } : { autoAlpha: 0, display: "none" }),
		[isVisible]
	);

	return (
		<Tween {...rest} to={tweenProps}>
			{children}
		</Tween>
	);
};

export interface Props extends Partial<Omit<TweenProps, "from" | "to" | "fromTo">> {
	isVisible?: boolean;
}
