import { FieldType, FieldPropsAssociation } from "@core/types";

export const patterns = {
	code: String.raw`^[0-9]{4}$`,
	date: String.raw`^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$`,
	passport: String.raw`^[0-9]{4} [0-9]{6}$`,
	licenseSeriesAndNumber: String.raw`^[0-9a-zA-Z]{4} [0-9]{6}$`,
	ctcSeriesAndNumber: String.raw`^[0-9]{2}[А-ЯA-Z]{2} [0-9]{6}$`,
	policyInsurance: String.raw`^[А-Я]{3} [0-9]{10}$`,
	vin: String.raw`^[0-9a-zA-Z]{15,50}$`,
	phone: String.raw`\+?7[ ]?[0-9]{3}[ ]?[0-9]{3}\-?[0-9]{2}\-?[0-9]{2}`,
	phoneInput: String.raw`^\+7 [0-9]{3} [0-9]{3}-[0-9]{2}-[0-9]{2}$`,
	ctc: String.raw`^[0-9]{10}$`,
	ptc: String.raw`^[0-9]{2}[А-ЯA-Z]{2} [0-9]{6}$`,
	anyNumber: String.raw`^[0-9]+$`,
	firstname: String.raw`^[А-Яа-я]{1,50}$`,
	surname: String.raw`^[А-Яа-я]{1,145}$`,
	middlename: String.raw`^[А-Яа-я]{1,100}$`,
};

export const defaultErrorMessage =
	"Проверь правильность введенных данных и повтори попытку чуть позже";

const asMaskPropsType = <T extends Record<string, Inputmask.Options>>(object: T) => object;

const asDatepickerPropsType = <
	T extends Record<
		string,
		(
			options: Partial<FieldPropsAssociation[FieldType.InputDatepicker]["datepickerOptions"]>
		) => Pick<FieldPropsAssociation[FieldType.InputDatepicker], "maskOptions" | "datepickerOptions">
	>
>(
	object: T
) => object;

export const maskOptions = asMaskPropsType({
	date: {
		regex: patterns.date,
		autoUnmask: false,
	},
	year: {
		regex: patterns.code,
		autoUnmask: false,
	},
	groupedNumber: {
		groupSeparator: " ",
		alias: "numeric",
		placeholder: "",
		autoGroup: true,
		rightAlign: false,
		digits: 0,
		digitsOptional: false,
		clearMaskOnLostFocus: false,
	},
});

export const datepickerOptions = asDatepickerPropsType({
	date: (options) => ({
		maskOptions: maskOptions.date,
		datepickerOptions: {
			format: "DD.MM.YYYY",
			...options,
		},
	}),
	year: (options) => ({
		maskOptions: maskOptions.year,
		datepickerOptions: {
			format: "YYYY",
			rangeType: "years",
			hasLockedRangeType: true,
			...options,
		},
	}),
});
