import React, { useCallback } from "react";
import { Observer } from "mobx-react-lite";

import { Modal } from "@components/smart/Modal";
import { ErrorField } from "@components/smart/ErrorField";

import { LoadButton } from "@components/ui/LoadButton";

import { useForm, useModal, useAPI } from "@core/hooks";
import { calculateServices } from "@core/services";
import { ModalType } from "@core/types";
import { formSchema } from "./schemas";

import styles from "./styles.module.scss";
import { normalizePhoneNumber } from "@core/utils";

export const NeedManagerHelp: React.FC<Props> = ({ formData }) => {
	const modal = useModal();
	const form = useForm(formSchema);
	const saveDataRequest = useAPI(calculateServices.saveManager);

	const handleFormSubmit = useCallback(
		(event: React.FormEvent) => {
			event.preventDefault();

			if (form.validate()) {
				saveDataRequest
					.call({
						...(formData as any),
						policyholder_name: form.getValue("firstname"),
						policyholder_phone: `+7${normalizePhoneNumber(form.getValue("phone"))}`,
						priority: 1,
					})
					.then(() => {
						form.clear();
						modal.show(ModalType.OrderBeingProcessed);
					})
					.catch(console.error);
			}
		},
		[form, formData, modal, saveDataRequest]
	);

	return (
		<Modal type={ModalType.NeedManagerHelp} className={styles.modal}>
			<div className={styles.content}>
				<div className={styles.titleGroup}>
					<p className={styles.subtitle}>
						Оставьте заявку сейчас и наш менеджер расчитает для вас стоимость полиса с максимальной
						выгодой
					</p>
				</div>
				<form onSubmit={handleFormSubmit}>
					<div className={styles.form}>
						<div className={styles.field}>
							<Observer>{() => <ErrorField {...form.bind("firstname")} />}</Observer>
						</div>
						<div className={styles.field}>
							<Observer>{() => <ErrorField {...form.bind("phone")} />}</Observer>
						</div>
					</div>
					<div>
						<Observer>
							{() => (
								<LoadButton
									type='submit'
									theme='primary-red'
									className={styles.button}
									loaded={saveDataRequest.isPending()}>
									Отправить
								</LoadButton>
							)}
						</Observer>
					</div>
				</form>
			</div>
		</Modal>
	);
};

export interface Props {
	formData: Record<string, any>;
}
