/**
 * Добавляет лидирующие нули к числу до тех пор, пока оно не достигнет определенной длины
 * @param number - Число, к которому необходимо добавить нули
 * @param neededNumberLength - Необходимая длина числа. Данный параметр отвечает за то, чтобы выходное число с нулями было определенной длины. Например: если на вход, данная функция принимает чило "2" и neededNumberLength будет равен "4" то на выходе получится чило "0002"
 */
export function addLeadingZeros(number: number, neededNumberLength: number = 2) {
	let zeros = "0".repeat(neededNumberLength).split("");
	const isNegative = number < 0;
	const numberAsString = String(Math.abs(number));

	for (let i = numberAsString.length - 1; i >= 0; i--) {
		const zerosIndex = zeros.length - (numberAsString.length - i);

		if (zerosIndex >= 0) {
			zeros[zerosIndex] = numberAsString[i];
		} else {
			zeros = [numberAsString[i], ...zeros];
		}
	}

	const zerosWithNumber = zeros.join("");

	return isNegative ? `-${zerosWithNumber}` : zerosWithNumber;
}

/**
 * Преобразует строку из camelCase в pascalCase
 * Пример: `simplyTransformToPascalCase("helloWorld") -> "HelloWorld"`
 * @param string
 * @returns
 */
export function simplyTransformToPascalCase(string: string) {
	const [firstLetter, ...rest] = string;

	return `${firstLetter.toUpperCase()}${rest.join("")}`;
}

/**
 * Возвращает позицию курсора внутри элемента
 * @param element - Элемент, внутри которого надо вычислить позицию мыши
 * @param mousePosition - Позиция мыши относительно документа
 */
export function calculateMousePositionInsideElement(
	element: HTMLElement | SVGElement | Document | null,
	mousePosition: { x: number; y: number }
) {
	const offset =
		element === document ? { top: 0, left: 0 } : calculateElementOffset(element as HTMLElement);

	return {
		x: mousePosition.x - offset.left || 0,
		y: mousePosition.y - offset.top || 0,
	};
}

/**
 * Безопасно возвращает DOMRect элемента
 */
export function safelyGetElementDOMRect(element: HTMLElement | SVGElement | null) {
	return (
		element?.getBoundingClientRect() || {
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			width: 0,
			height: 0,
			x: 0,
			y: 0,
		}
	);
}

/**
 * Вычисляет позицию элемента относительно документа
 * @param element - Элемент, позицию которого надо вычислить
 */
export function calculateElementOffset(element: HTMLElement | SVGElement | null) {
	const DOMRect = safelyGetElementDOMRect(element);

	const body = document.body;
	const documentElement = document.documentElement;

	const scrollTop = window.pageYOffset || documentElement.scrollTop || body.scrollTop;
	const scrollLeft = window.pageXOffset || documentElement.scrollLeft || body.scrollLeft;

	const clientTop = documentElement.clientTop || body.clientTop || 0;
	const clientLeft = documentElement.clientLeft || body.clientLeft || 0;

	const top = DOMRect.top + scrollTop - clientTop;
	const left = DOMRect.left + scrollLeft - clientLeft;

	return { top: Math.round(top), left: Math.round(left) };
}

/**
 * Возвращает позицию курсора из события
 */
export function getMousePositionFromEvent(event: TouchEvent | MouseEvent) {
	const hasTouches = "touches" in event;
	// @ts-ignore
	const targetProperty = hasTouches ? event.touches[0] : event;

	return {
		x: targetProperty.pageX || 0,
		y: targetProperty.pageY || 0,
	};
}

/**
 * Возвращает случайное целое число в интервале [min, max]
 */
export function generateRandomNumber(min: number, max: number) {
	min = Math.ceil(min);
	max = Math.floor(max);

	return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Объединяет все коллбэки
 */
export function mergeCallbacks<T extends Function>(...callbacks: (T | null | undefined)[]) {
	const filteredCallbacks = callbacks.filter(Boolean) as T[];

	if (filteredCallbacks.length === 0) {
		return null;
	}

	return (...args: any[]) => {
		for (const callback of filteredCallbacks) {
			callback(...args);
		}
	};
}

/**
 * Объединяет все рефы
 */
export function mergeRefs<E = HTMLElement>(
	...refs: (React.ForwardedRef<any> | null | undefined)[]
) {
	const filteredRefs = refs.filter(Boolean);

	if (filteredRefs.length === 0) {
		return null;
	}

	return (instance: E | null) => {
		for (const ref of filteredRefs) {
			if (typeof ref === "function") {
				ref(instance);
			} else if (ref) {
				ref.current = instance;
			}
		}
	};
}

/**
 * Сохраняет число в интервале [min, max]
 * @param number - Число, границы которого необходимо сохранить
 * @param bounds
 * @param bounds.min - Минимальная граница числа
 * @param bounds.max - Максимальная граница числа
 */
export function clamp(number: number, bounds: { min: number; max: number }) {
	number = Math.max(number, bounds.min);
	number = Math.min(number, bounds.max);

	return number;
}

/**
 * Возвращает нормализированное число в интервале [0, 1].
 * @param number - Число, которое необходимо нормализировать
 * @param bounds
 * @param bounds.min - Минимальная граница числа
 * @param bounds.max - Максимальная граница числа
 */
export function normalizeNumber(number: number, bounds: { min: number; max: number }) {
	return clamp((number - bounds.min) / (bounds.max - bounds.min) || 0, {
		min: 0,
		max: 1,
	});
}

/**
 * Безопасно парсит JSON
 */
export function parseJSON<T extends Record<string, any> | any[] = Record<string, any>>(json: any) {
	try {
		return JSON.parse(json) as T;
	} catch (e) {
		return null;
	}
}

/**
 * Возвращает элемент проскраливаемый элемент
 */
export function pickScrollingElement(element: HTMLElement | Document) {
	return (element === document ? element.scrollingElement : element) as HTMLElement;
}

/**
 * Производит редирект на опредённый URL
 */
export function redirect(
	url: string,
	{ asLink = true, newTab = false }: { asLink?: boolean; newTab?: boolean } = {}
) {
	if (newTab) {
		window.open(url, "_blank");
	} else {
		if (asLink) {
			window.location.href = url;
		} else {
			window.location.replace(url);
		}
	}
}

/**
 * Аккумулирует значения массива в объект, где каждый элемент массива явялется ключом в выходном объекте
 */
export function reduceToObject<T extends string, R>(
	array: T[],
	callback: (item: T, index: number) => R,
	emitNullableValues: boolean = false
) {
	return array.reduce((acc, item, index) => {
		const value = callback(item, index);

		if ((emitNullableValues && (value || typeof value === "string")) || !emitNullableValues) {
			return { ...acc, [item]: value };
		}

		return acc;
	}, {} as Record<T, R>);
}

/**
 * Случайным образом перемешивает массив
 * @param array - Массив, который необходимо перемешать
 */
export function shuffleArray<E>(array: E[]) {
	const output: E[] = [...array];

	for (let i = 0; i < array.length - 1; i++) {
		const j = Math.floor(Math.random() * (i + 1));

		[output[i], output[j]] = [output[j], output[i]];
	}

	return output;
}

/**
 * Генерирует чанки исходя из количества количества элементов и длины каждого чанка
 * @param arrayLength - Суммарное длина элементов чанков
 * @param chunkLength - Длина каждого чанка
 * @param options
 * @param options.filler - То, чем заполнить чанки.
 */
export function createEmptyChunks<E = number>(
	arrayLength: number,
	chunkLength: number,
	options: { filler?: E } = {}
) {
	const chunksCount = Math.ceil(arrayLength / chunkLength);
	const chunks = createArray(chunksCount).map(() => []) as E[][];

	for (let i = 0; i < arrayLength; i++) {
		const chunkIndex = Math.floor(i / chunkLength);

		chunks[chunkIndex].push((options.filler || 0) as E);
	}

	return chunks;
}

/**
 * Разбивает массив на чанки
 * @param array - Массив, который необходимо разбить на чанки
 * @param chunkLength - Длина каждого чанка
 */
export function splitIntoChunks<T>(array: T[], chunkLength: number) {
	const generatedChunks = createEmptyChunks<T>(array.length, chunkLength);

	return generatedChunks.map((chunk, index1) => {
		return chunk.map((_, index2) => array[index1 * chunkLength * index2]);
	});
}

/**
 * Вычисляет скидку из цены
 * @param price - Цена
 * @param discount - Скидка
 * @param asCashback - Если установлено true, то discount будет вычитаться как кэшбек, а не как скидка в процентах
 */
export function subtractDiscountFromPrice(
	price: number,
	discount: number,
	asCashback: boolean = false
) {
	if (asCashback) {
		return price - discount;
	}

	return price - (price / 100) * discount;
}

/**
 * Вызывает событие элемента.
 * Данный метод необходим для вызова Synethic событий React.
 * @param element
 * @param eventName - Название события
 * @param options
 * @param options.asValueSetter - Вызвать событие с нативным Value-Сеттером, для триггера синетических событий, таких как: `onChange`, `onInput` и т.д
 * @param options.value - Значение, которое необходим присвоить элементу в случае, если `options.asValueSetter` установлен `true`
 */
export function triggerEvent(
	element: HTMLElement | null,
	eventName: string,
	options: { asValueSetter?: boolean; value?: string } = {}
) {
	if (element) {
		const event = document.createEvent("HTMLEvents");

		if (options.asValueSetter && typeof options.value === "string") {
			const nativeFieldValue = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value");

			if (nativeFieldValue && nativeFieldValue.set) {
				const nativeFieldValueSetter = nativeFieldValue.set;

				nativeFieldValueSetter.call(element, options.value);
			}
		}

		event.initEvent(eventName, true, true);
		element.dispatchEvent(event);
	}
}

/**
 * Нормализирует номер телефона. Например: +7 123 456 78-90 => 1234567890
 * @param phoneNumber - Номер телефона
 * @returns
 */
export function normalizePhoneNumber(phoneNumber: string = "") {
	phoneNumber = phoneNumber.replace(/[^\d]+/g, "");

	if (phoneNumber.length >= 11) {
		phoneNumber = phoneNumber.slice(1, 11);
	}

	return phoneNumber;
}

/**
 * Преобразует строку в объект, где ключом объекта является pieces[number].name, а значением - часть строки, где начало - сумма длин все предыдущих частей, а конец - сумма длин всех предыдущих частей + длина текущей части
 *
 * Например: при входной строке "ABCDE" и частях [{name: "first", length: 2}, {name: "second", length: 3}] - получим {first: "AB", second: "CDE"}
 *
 * @param target - Строка, которую необходимо разбить на части
 * @param pieces
 * @param pieces.name - Названчие части
 * @param pieces.length - Длина части
 */
export function splitStringIntoPieces<T extends string>(
	target: string,
	pieces: { name: T; length: number }[]
) {
	let currentOffset = 0;

	return pieces.reduce((acc, offset) => {
		const res = {
			...acc,
			[offset.name]: target?.slice(currentOffset, currentOffset + offset.length) || "",
		};

		currentOffset += offset.length;

		return res;
	}, {} as Record<T, string>);
}

/**
 * Создёт и возвращает пустой массив
 * @param arrayLength - Длина массива
 * @returns
 */
export function createArray(arrayLength: number) {
	return Array(arrayLength).fill(null) as null[];
}

export function excludeFilterArray<T>(array: T[], exclude: T[] = []) {
	if (exclude.length === 0) {
		return array;
	}

	return array.filter((item) => !exclude.includes(item));
}

export function includeFilterArray<T>(array: T[], include: T[] = []) {
	if (include.length === 0) {
		return array;
	}

	return array.filter((item) => include.includes(item));
}

export function getNumberOffset(count: number, offset: number) {
	const safeOffset = offset % count;

	if (safeOffset < 0) {
		return count + safeOffset;
	}

	return safeOffset;
}

/**
 * Добавляет параметры к URL
 * @param url - URL, к которому необходимо добавить параметры
 * @param params - Объект параметров, в котором ключ - название параметра, а значение - значение параметра
 */

export function addParams(url: string, params: { [key: string]: any }) {
	const paramsEntries = Object.entries(params);

	if (paramsEntries.length) {
		return (
			url +
			paramsEntries.reduce((acc, param, index) => {
				if (index === 0) {
					return `?${param[0]}=${param[1]}`;
				}

				return `${acc}&${param[0]}=${param[1]}`;
			}, "")
		);
	}

	return url;
}

/**
 * Определенным образом форматирует номер телефона
 * @param phoneNumber - Номер телефона, который необходимо форматировать
 * @param pieces - Части, где length - длина части строки, после которой необходимо поставить delimiter
 */

export function formatPhoneNumber(
	phoneNumber: string,
	pieces: { length: number; delimiter: string }[]
) {
	const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
	const phoneNumberAsPieces = splitStringIntoPieces(
		normalizedPhoneNumber,
		pieces.map((piece, index) => ({ length: piece.length, name: String(index) }))
	);

	return pieces
		.reduce((acc, piece, index) => {
			return [...acc, phoneNumberAsPieces[index], piece.delimiter];
		}, [] as string[])
		.join("");
}

/**
 * Возвращает содержимое тега
 * @param tags - Название тега
 */

export function getTextInsideTag(tags: string) {
	return [...(tags.match(/<([\w]+)[^>]*>(.*?)<\/\1>/) || [])][2] || null;
}

/**
 * Делает первую букву строки заглавной
 * @param string - Строка, в которой необходимо сделать первую букву заглавной
 */

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function calculatePriceWithDiscount(currentPrice: number, discount: number) {
	return currentPrice - (currentPrice / 100) * discount;
}

export function calculateOriginalPrice(currentPrice: number, percents: number) {
	return (currentPrice / percents) * 100;
}
