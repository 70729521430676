import React, { forwardRef } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

export const CheckboxBase = forwardRef<HTMLInputElement, Props>(
	({ children, className, asRadio = false, ...rest }, ref) => {
		return (
			<label className={classNames(styles.checkboxBase, className)}>
				<input
					ref={ref}
					type={asRadio ? "radio" : "checkbox"}
					className={styles.nativeInput}
					{...rest}
				/>
				{children}
			</label>
		);
	}
);

export interface Props extends Omit<React.ComponentProps<"input">, "type"> {
	asRadio?: boolean;
}
